import React, {useEffect, useState} from 'react';
import {
  Row,
  Col,
  Container,
  Modal,
  Card,
  CardBody,
  Form,
  FormFeedback,
  Input,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';
import Select from 'react-select';

import Flatpickr from 'react-flatpickr';
import * as Yup from 'yup';

// Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import InvoiceListTable from './accounttable/accountsTable';
import {useDispatch, useSelector} from 'react-redux';
import {
  addAccounts,
  deleteAccount,
  getAccounts,
  getAccountsCounts,
  getAccountsGroups,
  getAccountsTransactionExistence
} from '../../../store/actions';
import {useFormik} from 'formik';
import {REMOVE_ACCOUNT_DATA} from '../../../store/Accounts/actionTypes';
import moment from 'moment';

const Accounts = (args) => {
  document.title = 'Accounts  - account-books';
  const [selectedGroup, setSelectedGropu] = useState('');
  const [selectedtranType, setSelectedtranType] = useState('');
  const [currentUser, serCurrentUser] = useState('');
  const [text, setText] = useState('');
  const [alias, setAlias] = useState('');

  const [modal_invoice, setmodal_invoice] = useState(false);
  const [deleteUser, setdeleteUser] = useState('');
  const [modal, setModal] = useState(false);
  const [bankOption, setBankoption] = useState(false);

  const toggle = () => {
    onclearValue();
    setModal(!modal);
  };
  const dispatch = useDispatch();
  function tog_invoice(type) {
    if (type === 'add') {
      onclearValue();
      validation.resetForm();
    }
    setmodal_invoice(!modal_invoice);
  }

  useEffect(() => {
    dispatch(getAccounts());
    dispatch(getAccountsGroups());
    dispatch(getAccountsCounts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAccountsTransactionExistence(currentUser ? currentUser?.id : 0));
  }, [currentUser, dispatch]);

  const onDelteClick = (user) => {
    setdeleteUser(user);
    setModal(true);
  };
  // Wizard Modal
  const {accounts, accountGroups, accCount, isAccountTransExists} = useSelector((state) => ({
    error: state.accounts.error,
    accounts: state.accounts.accounts,
    accountGroups: state.accounts.accountGroups,
    accCount: state.accounts.accCount,
    isAccountTransExists: state.accounts.isAccountTransExists
  }));

  const handleYearChange = (selectedYear) => {
    setSelectedGropu(selectedYear);
  };

  const onclearValue = () => {
    setSelectedGropu('');
    setSelectedtranType('');
    serCurrentUser('');
    setdeleteUser('');
    setText('');
    validation.resetForm();
    setAlias('');
    setmodal_invoice(false);
    setModal(false);
    dispatch({
      type: REMOVE_ACCOUNT_DATA
    });
  };
  const deleteUserAccount = (id) => {
    dispatch(deleteAccount(id, onclearValue()));
  };

  const editClick = (data) => {
    serCurrentUser(data);
    setText(data?.notes);
    setAlias(data?.alias);
    setmodal_invoice(true);
    handleYearChange({
      value: data.parentGroupID,
      label: data.groupName
    });
    handletranTypeChange({
      value: data.tranType,
      label: data?.tranType === 'C' ? 'Cr' : 'Dr'
    });
  };

  const handletranTypeChange = (selectedtranType) => {
    setSelectedtranType(selectedtranType);
  };
  const transTpes = [
    {
      name: 'Cr',
      value: 'C'
    },
    {
      name: 'Dr',
      value: 'D'
    }
  ];

  useEffect(() => {
    if (selectedGroup.label === 'Bank Accounts' || selectedGroup.label === 'Bank O D') {
      setBankoption(true);
    } else {
      setBankoption(false);
    }
  }, [selectedGroup]);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      accountCode: currentUser ? currentUser?.accountCode : accCount,
      accountName: currentUser ? currentUser.name : '',
      openingBalance: currentUser ? currentUser.openingBalance : '',
      tranType: currentUser ? currentUser.tranType : '',
      commission: currentUser ? currentUser.commission : '',
      groupName: currentUser ? currentUser.groupName : '',
      accountNo: currentUser ? currentUser.accountNo : '',
      ifscCode: currentUser ? currentUser.ifscCode : '',
      openingDate: currentUser ? currentUser.openingDate : ''
    },
    validationSchema: Yup.object({
      accountCode: Yup.string().required('Please enter  account code'),
      commission: Yup.number().required('Please enter  account commission'),
      tranType: Yup.string().required('Please enter  account tranType'),
      openingDate: Yup.string().required('Please select opening date'),
      groupName: Yup.string().required('Please enter  account groupName')?.trim(),
      accountName: Yup.string().required('Please enter  account name').trim('Please enter  account name').strict(false),
      openingBalance: Yup.number().required('Please enter opening balance ').min(0, 'Balance should be not negative ')
    }),
    onSubmit: (values) => {
      values.notes = text;
      if (currentUser?.id) {
        values.ID = currentUser.id;
        if (!values?.groupId) {
          values.groupID = currentUser.groupID;
        }
      }
      if (!bankOption) {
        values.accountNo = '';
        values.ifscCode = '';
      }
      values.ifscCode = values?.ifscCode.trim();
      values.alias = alias;
      values.openingDate = moment(values.openingDate).format('YYYY-MM-DD');
      dispatch(addAccounts(values, onclearValue));
    }
  });

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid={true}>
          <Breadcrumbs
            title='Account'
            breadcrumbItem='Accounts
'
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className='position-relative'>
                    <div className='modal-button mt-2'>
                      <Row className='align-items-start'>
                        <Col className='col-sm'>
                          <div>
                            <button
                              type='button'
                              className='btn btn-success mb-4'
                              data-bs-toggle='modal'
                              data-bs-target='#addInvoiceModal'
                              onClick={() => {
                                tog_invoice('add');
                              }}
                            >
                              <i className='mdi mdi-plus me-1'></i> Add New Accounts
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div id='table-invoices-list'>
                    <InvoiceListTable
                      tableData={accounts}
                      onEditClick={(data) => editClick(data)}
                      ondeleteClick={(data) => onDelteClick(data)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Invoice List Modal */}

      <Modal size='lg' isOpen={modal_invoice} className='addInvoiceModal' centered>
        <div className='modal-header'>
          <h5 className='modal-title' id='addInvoiceModalLabel'>
            {' '}
            {currentUser ? 'Edit' : 'Add'} Account{' '}
          </h5>
          <button
            onClick={() => {
              setmodal_invoice(false);
            }}
            type='button'
            className='btn-close'
            aria-label='Close'
          ></button>
        </div>
        <div className='modal-body p-4'>
          <Form className='form-horizontal' onSubmit={validation.handleSubmit}>
            <Row>
              <Col>
                <Label>
                  Account Code <span className='text-danger'>*</span>
                </Label>
                <Input
                  name='accountCode'
                  className='form-control'
                  placeholder='Enter Account Code'
                  type='text'
                  disabled
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.accountCode || ''}
                  invalid={validation.touched.accountCode && validation.errors.accountCode ? true : false}
                />
                {validation.touched.accountCode && validation.errors.accountCode ? (
                  <FormFeedback type='invalid'>
                    <div className='text-error'>{validation.errors.accountCode}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <Label>
                  Account Name <span className='text-danger'>*</span>
                </Label>
                <Input
                  name='accountName'
                  className='form-control'
                  placeholder='Enter Account Name'
                  type='text'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.accountName || ''}
                  invalid={validation.touched.accountName && validation.errors.accountName ? true : false}
                />
                {validation.touched.accountName && validation.errors.accountName ? (
                  <FormFeedback type='invalid'>
                    <div>{validation.errors.accountName}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <Label>Alias</Label>
                <Input className='form-control' placeholder='Enter Alias' value={alias} onChange={(e) => setAlias(e.target.value)} />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col md='4'>
                <Label>
                  Opening Date <span className='text-danger'>*</span>
                </Label>
                <Flatpickr
                  name='openingDate'
                  disabled={isAccountTransExists === 1 ? true : false}
                  className='form-control d-block'
                  placeholder='Select opening date'
                  value={new Date(validation.values.openingDate)}
                  options={{
                    mode: 'single',
                    disableMobile: true,
                    dateFormat: 'd/m/Y'
                  }}
                  onChange={(e) => {
                    validation.setFieldValue('openingDate', e[0]);
                  }}
                />
                {validation.errors.openingDate ? <div className='text-error'>{validation.errors.openingDate}</div> : null}
              </Col>
              <Col md='4'>
                <Label>
                  Opening Balance <span className='text-danger'>*</span>
                </Label>
                <Input
                  name='openingBalance'
                  className='form-control'
                  placeholder='Enter Opening Balance'
                  disabled={isAccountTransExists === 1 ? true : false}
                  step={0.0031}
                  type='number'
                  onChange={(e) => {
                    const value = e.target.value;
                    const val = ('' + value).replace('.', '');
                    if (val?.length > 3) {
                      const suppressedValue = Number(+val / 1000).toFixed(3);
                      validation?.setFieldValue('openingBalance', suppressedValue);
                    } else {
                      validation?.setFieldValue('openingBalance', val);
                    }
                  }}
                  onBlur={(e) => {
                    if (e?.target.value?.length <= 3) {
                      const formatted = Number(e?.target.value / 1000).toFixed(3);
                      if (validation?.values?.amount === Number(e.target.value)) {
                        return;
                      } else {
                        validation.setFieldValue('openingBalance', formatted);
                      }
                    }
                  }}
                  value={validation.values.openingBalance || ''}
                  invalid={validation.touched.openingBalance && validation.errors.openingBalance ? true : false}
                />
                {validation.touched.openingBalance && validation.errors.openingBalance ? (
                  <FormFeedback type='invalid'>
                    <div>{validation.errors.openingBalance}</div>
                  </FormFeedback>
                ) : null}
              </Col>
              <Col md='4'>
                <Label>
                  Type <span className='text-danger'>*</span>
                </Label>
                <Select
                  placeholder='Transaction Type'
                  openMenuOnFocus={true}
                  value={selectedtranType}
                  onChange={(selectedOption) => {
                    handletranTypeChange(selectedOption);
                    validation.setFieldValue('tranType', selectedOption?.value);
                    validation.handleChange('tranType');
                  }}
                  isSearchable={true}
                  options={transTpes.map((value) => ({
                    value: value.value,
                    label: value.name
                  }))}
                  name='tranType'
                  isDisabled={isAccountTransExists === 1 ? true : false}
                  isLoading={false}
                  loadingMessage={() => 'Fetching Transaction Type'}
                  noOptionsMessage={() => 'Transaction Type appears here'}
                />
                {validation.touched.tranType && validation.errors.tranType ? (
                  <div className='text-error'>{validation.errors.tranType}</div>
                ) : null}
              </Col>
            </Row>

            {bankOption ? (
              <Row className='mt-4'>
                <Col md='6'>
                  <Label>
                    IFSC Code <span className='text-danger'>*</span>
                  </Label>
                  <Input
                    name='ifscCode'
                    className='form-control'
                    placeholder='Enter IFSC code'
                    type='text'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.ifscCode?.trimStart()}
                    invalid={validation.touched.ifscCode && validation.errors.ifscCode ? true : false}
                  />
                  {validation.touched.ifscCode && validation.errors.ifscCode ? (
                    <FormFeedback type='invalid'>
                      <div>{validation.errors.ifscCode}</div>
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md='6'>
                  <Label>
                    Account Number <span className='text-danger'>*</span>
                  </Label>
                  <Input
                    name='accountNo'
                    className='form-control'
                    placeholder='Enter account number'
                    type='number'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.accountNo}
                    invalid={validation.touched.accountNo && validation.errors.accountNo ? true : false}
                  />
                  {validation.touched.accountNo && validation.errors.accountNo ? (
                    <FormFeedback type='invalid'>
                      <div>{validation.errors.accountNo}</div>
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            ) : null}
            <Row className='mt-4'>
              <Col md='6'>
                <Label>
                  Group Name <span className='text-danger'>*</span>
                </Label>
                <Select
                  placeholder='Select group name'
                  value={selectedGroup}
                  openMenuOnFocus={true}
                  onChange={(selectedOption) => {
                    handleYearChange(selectedOption);
                    // handleYearChange(selectedOption, values);
                    // values.groupName = selectedOption.value;
                    validation.setFieldValue('groupName', selectedOption?.label);
                    validation.setFieldValue('groupId', selectedOption?.value);
                    validation.handleChange('groupName');
                    validation.handleChange('groupId');
                  }}
                  isSearchable={true}
                  options={accountGroups.map((value) => ({
                    value: value.id,
                    label: value.groupName
                  }))}
                  name='groupName'
                  isLoading={false}
                  loadingMessage={() => 'Fetching account Name'}
                  noOptionsMessage={() => 'accounts appears here'}
                />
                {validation.touched.groupName && validation.errors.groupName ? (
                  <div className='text-error'>{validation.errors.groupName}</div>
                ) : null}
              </Col>
              <Col md='6'>
                <Label>
                  Commission <span className='text-danger'>*</span>
                </Label>
                <Input
                  disabled={isAccountTransExists === 1 ? true : false}
                  name='commission'
                  className='form-control'
                  placeholder='Enter commission'
                  step={0.0031}
                  type='number'
                  onChange={(e) => {
                    const value = e.target.value;
                    const val = ('' + value).replace('.', '');
                    if (val?.length > 3) {
                      const suppressedValue = Number(+val / 1000).toFixed(3);
                      validation?.setFieldValue('commission', suppressedValue);
                    } else {
                      validation?.setFieldValue('commission', val);
                    }
                  }}
                  onBlur={(e) => {
                    if (e?.target.value?.length <= 3) {
                      const formatted = Number(e?.target.value / 1000).toFixed(3);
                      if (validation?.values?.amount === Number(e.target.value)) {
                        return;
                      } else {
                        validation.setFieldValue('commission', formatted);
                      }
                    }
                  }}
                  value={validation.values.commission || ''}
                  invalid={validation.touched.commission && validation.errors.commission ? true : false}
                />
                {validation.touched.commission && validation.errors.commission ? (
                  <FormFeedback type='invalid'>
                    <div>{validation.errors.commission}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col>
                <Label>Notes </Label>
                <textarea
                  rows='4'
                  name='notes'
                  className='form-control'
                  placeholder='Enter Notes...'
                  onChange={(e) => setText(e?.target?.value)}
                  value={text}
                ></textarea>
              </Col>
            </Row>
            <div className='mt-3 text-end'>
              <button className='btn btn-primary btn-block' type='submit'>
                {currentUser.accountCode ? 'Update' : 'Save'}
              </button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Delete Account </ModalHeader>
        <ModalBody>Are you sure want to delete this account ?</ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => deleteUserAccount(deleteUser?.id)}>
            Delete
          </Button>{' '}
          <Button color='secondary' onClick={() => toggle()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Accounts;
