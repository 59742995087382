import {
  ACCOUNT_FETCH_START,
  ACCOUNT_FETCH_ERROR,
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNT_GROUP_FETCH_SUCCESS,
  ACCOUNT_GROUP_FETCH_COUNT_SUCCESS,
  ADD_ACCOUNT_DATA,
  REMOVE_ACCOUNT_DATA,
  GET_ACCOUNT_BY_BANK,
  FETCH_ACCOUNT_TRANSACTION_EXISTENCE
} from './actionTypes';

const initialState = {
  error: '',
  accounts: [],
  accCount: 0,
  accountGroups: [],
  currentAccountData: {},
  accByBank: []
};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_FETCH_START:
      state = {
        ...state,
        error: ''
      };
      break;
    case ACCOUNT_FETCH_SUCCESS:
      state = {
        ...state,
        error: '',
        accounts: action.payload
      };
      break;
    case ACCOUNT_GROUP_FETCH_SUCCESS:
      state = {
        ...state,
        error: '',
        accountGroups: action.payload
      };
      break;
    case ACCOUNT_FETCH_ERROR:
      state = {
        ...state,
        error: action.payload
      };
      break;
    case ADD_ACCOUNT_DATA:
      state = {
        ...state,
        currentAccountData: action.payload
      };
      break;
    case REMOVE_ACCOUNT_DATA:
      state = {
        ...state,
        currentAccountData: {}
      };
      break;
    case ACCOUNT_GROUP_FETCH_COUNT_SUCCESS:
      state = {
        ...state,
        error: action.payload,
        accCount: action.payload
      };
      break;
      case FETCH_ACCOUNT_TRANSACTION_EXISTENCE:
      state = {
        ...state,
        isAccountTransExists: action.payload
      };
      break;
    case GET_ACCOUNT_BY_BANK:
      state = {
        ...state,
        error: action.payload,
        accByBank: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default accounts;
