export const LOGIN_USER_URL = 'Auth/Login';
export const LOGIN_BLOCK_USER_URL = 'Auth/BlockUserByCaptcha';
export const LOGIN_UNBLOCK_USER_URL = 'User/UnblockUserByCaptcha';
export const COMPANY_LIST_AUTH = 'Auth/GetCompaniesByID';

export const MENU_ROLE = 'Auth/RoleBasedMenu';
export const COMPANY_LIST = 'company/GetList';
export const COMPANY_AUTH = 'Auth/CompanySetUp';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const FORGOT_PASSWORD = 'Auth/ForgotPassword';
export const CHANGE_PASSWORD = 'Auth/ChangeFPassword';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';

//Accounts
export const GET_ACCOUNTS = 'Accounts/GetList';
export const GET_ACCOUNTS_TRANSACTION_EXISTENCE = 'Accounts/CheckTransactionsExists';
export const GET_ACCOUNTS_BY_BANK = 'Accounts/GetByBankAccount';
export const GET_USERS_LIST = 'User/GetUsersList';

//accountGroups
export const GET_ACCOUNTS_GROUP = 'accountgroup/GetList';
export const GET_ACCOUNTS_GROUP_CODE = 'CompanyConfiguration/GetNextAccCode';
export const ADD_ACCOUNT_GROUP = '/accounts/SaveAccounts';
export const DELETE_ACCOUNT_GROUP = '/Accounts/DeleteAccounts/';

//reports
export const TRIAL_BALANCE = 'Accounts/GetTrailBalanceReport';
export const LEDGER_REPORT = 'Accounts/GetLedgerReport';
export const LEDGER_RECONCILIATION = 'Reconciliation/GetReconciliationReport';
export const LEDGER_UPDATE_RECONCILIATION = 'Reconciliation/UpdateReconciliation';
export const DOWNLOAD_LEDGER_REPORT = 'ExportPDF/ExportLedgerpdf';
export const DOWNLOAD_LEDGER_REPORT_EXCEL = 'ExportExcel/ExportLedgerExcel';
export const DOWNLOAD_RECONCILIATION_REPORT_EXCEL = 'ExportExcel/ExportReconciliationExcel';
export const DOWNLOAD_TRAIL_REPORT_PDF = 'ExportPDF/ExportTrialBalpdf';
export const DOWNLOAD_TRAIL_RECONCILIATION_PDF = 'ExportPDF/ExportReconciliationpdf';
export const DOWNLOAD_TRIAL_REPORT_EXCEL = 'ExportExcel/ExportTrialBalExcel';
export const LEADER_TRANSACTION_LIST = 'Transactions/GetById';
export const LEADER_LIST_JOURNAL = 'Journals/GetDetailsById';
export const LEDGER_LIST_SALES = 'SalesInvoice/GetByID';
export const LEDGER_LIST_PURCHASE = 'PurchaseInvoice/GetByID';
export const LEDGER_LIST_BANKBOOK = 'BankBook/GetById';
export const LEDGER_LIST_CASHBOOK = 'CashBook/GetById';

export const SAVE_USERS = '/User/SaveUser';

export const DELETE_USER_ACCOUNT = '/User/DeleteUsers/';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';

//Company configuration
export const COMAPNY_CONFIGURATION = '/Currency/GetList';
export const COMAPNY_CONFIGURATION_DATA = 'CompanyConfiguration/GetList';
export const COMAPNY_CONFIGURATION_UPDATE_DATA = 'CompanyConfiguration/UpdateCompanyConfiguration';
export const COMAPNY_CONFIG_PENDINGENTRY = 'CompanyConfiguration/GetPendingEntryCount';

//Common API
export const CITY_LIST = 'City/GetIsactiveList';
export const SUPPLIER_LIST = 'Common/GetSupplierList';

//PURCHASE
export const PURCHASE = 'Purchase';
//SALES
export const SALES = 'Sales';
//PRODUCTS
export const PRODUCTS = 'Products/';

//TransactionAPI

export const ADD_TRANJACTION_DATA = 'Transactions/SaveTransactions';
export const GET_TRANJACTION_DATA = 'Transactions/GetList';
export const DOWNLOAD_TRANJACTION_DATA = 'ExportPDF/ExportTransactionPDF';
export const DOWNLOAD_TRANJACTION_DATA_CSV = 'ExportExcel/ExportTransactionExcel';

export const DELETE_TRANJACTION_DATA = 'Transactions/DeleteTransactions';
export const CHECK_TRANJACTION_PASS = 'Transactions/VerifyTransactionPassword/';
export const CHANGE_TRANSACTION_STATUS = 'Transactions/ApproveTransaction/';
export const CHANGE_TRANSACTION_LEASER = 'Transactions/GetLedgerReportForTran';
//Journals
export const GET_JOURNALS_LIST = 'journals/GetList';
//CashBook API
export const GET_CASHBOOK_DATA = 'CashBook/GetList';
export const ADD_CASHBOOK_DATA = '/CashBook/SaveCashBook';
export const DELETE_CASHBOOK_DATA = '/CashBook/DeleteCashBook/';
export const APPROVE_CASHBOOK = '/CashBook/ApproveCashbook/';

export const DOWNLOAD_CASHBOOK_DATA = '/ExportPDF/ExportCashbookPDF';
export const DOWNLOAD_CASHBOOK_DATA_CSV = '/ExportExcel/ExportCashBookExcel';

//PRODUCTS

export const PRODUCTS_LIST_DATA = '/Products/GetList';
//CITYAPI
export const GET_CITY = '/city/GetList';
export const SAVE_CITY = '/city/SaveCity';
export const DELETE_CITY = '/city/DeleteCity/';

//BankBookAPI

export const BB_PDF = '/ExportPDF/ExportBankbookPDF';
export const BB_CSV = '/ExportExcel/ExportBankBookExcel';

export const BBC_PDF = '/ExportPDF/ExportBankookCommisiionPDF';
export const BBC_CSV = '/ExportExcel/ExportBankBookCommissionExcel';

export const BB_LIST = '/BankBook/GetList';
export const BB_SAVE = '/BankBook/SaveBankBook';
export const BB_DELETE = '/BankBook/DeleteBankBook/';

export const BB_COMM_LIST = '/BankBook/GetAccountAmountByPartyAccount/';
export const BB_COMM_SAVE = '/BankBookCommission/SaveBankBookCommission/';
export const BB_COMM_DELETE = '/BankBookCommission/DeleteBankBookCommission/';

export const BB_APPROVE = '/BankBook/ApproveBankbook';

//profit loss
export const PL_LIST = '/ProfitLoss/GetList';
export const PL_DETAIL = '/ProfitLoss/GetProfitLossDetailView';
