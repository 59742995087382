import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Table,
  Row
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import {REMOVE_ACCOUNT_DATA} from '../../store/Accounts/actionTypes';

import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import Flatpickr from 'react-flatpickr';

import {
  addCashbook,
  downloadCashBookList,
  getAccounts,
  getCashbookData,
  deleteCashBook,
  getCompanyConfigurtion,
  transactionPassword
} from '../../store/actions';

import AccountModal from '../Transactions/accountModal';
import CB_Table from './CBTable';
import TransactionPassword from '../Transactions/transactionPassword';

const CashB = (args) => {
  document.title = 'CashBook' + ' - account-books';

  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(0);

  const [modal_invoice, setmodal_invoice] = useState(false);
  const [modal, setModal] = useState(false);

  const [date, setDate] = useState([new Date()]);
  const [type, setType] = useState('I');

  let prevDate = moment().subtract(2, 'day');
  const [unlockModal, setUnlockModal] = useState(false);
  const [rowLock, setRowLock] = useState(false);
  const [tempUnlock, setTempUnlock] = useState(false);
  const [tableLock, setTableLock] = useState(false);
  const [lockType, setLockType] = useState('both');
  const [cashLock, setCashLock] = useState(false);
  const [cashStatus, setCashStatus] = useState(false);
  const [newAccountDataVal, setNewAccountDataVal] = useState({});

  const fp = useRef(null);

  const accResult = useSelector((state) => state.accounts);
  const currentAccountData = useSelector((state) => state.accounts.currentAccountData);
  const cashBookList = useSelector((state) => state.cashbook.cashbookData);
  const configList = useSelector((state) => state.companyConfiguration.configurationData);

  let defaultValue = {
    amount: '',
    account: '',
    narration: '',
    errShow: false
  };

  const [bookInfo, setBookInfo] = useState(defaultValue);
  const [errorMsg, setErrorMsg] = useState({
    account: '',
    narration: '',
    error: true
  });

  // lock unlock------------------------------------------------------

  useEffect(() => {
    configList.length > 0 &&
      configList?.map((a) => {
        if (a.code == 'CashLock') {
          a.value == 'true' ? setCashLock(true) : setCashLock(false);
        }
        if (a.code == 'CashStatus') {
          a.value == 'true' ? setCashStatus(true) : setCashStatus(false);
        }
      });
  }, [configList]);

  const toggleUnlockModal = () => {
    setUnlockModal(!unlockModal);
  };

  useEffect(() => {
    setLockType('both');
    setTempUnlock(false);
    if (new Date(date[0]) < new Date(prevDate._d)) {
      setRowLock(true);
      setTableLock(true);
    } else {
      setRowLock(false);
      setTableLock(false);
    }
  }, [date]);

  const handleOpenUnlock = useCallback((e, lockType) => {
    e.preventDefault();
    setUnlockModal(true);
    setLockType(lockType);
  }, []);

  const handleUnlock = (lockType) => {
    if (lockType == 'both') {
      setRowLock(false);
      setTableLock(false);
    } else {
      setRowLock(false);
      setTableLock(true);
    }
  };

  const toggleTempUnlock = () => {
    setTempUnlock(false);
  };

  //------------------------------------------------------------------------

  useEffect(() => {
    if (currentAccountData.id) {
      setBookInfo({
        ...bookInfo,
        account: {
          value: currentAccountData.id,
          label: currentAccountData.accountName
        }
      });
    } else {
      setBookInfo({
        ...bookInfo,
        account: ''
      });
    }
  }, [currentAccountData]);

  const onclearValue = (type1) => {
    setModal(false);
    if (type1) {
      setType(type1);
    }
    setErrorMsg({error: true});
    setBookInfo(defaultValue);
    setSearch('');
    setNewAccountDataVal()
  };

  const tog_invoice = () => {
    setmodal_invoice(!modal_invoice);
  };

  const toggle = () => {
    onclearValue();
    setModal(!modal);
  };

  useEffect(() => {
    let date1 = moment(date[0]).format('MM/DD/YYYY');
    dispatch(getCashbookData(date1, filter, type));
    setSearch('');
  }, [date, filter, type]);

  useEffect(() => {
    setFilteredData(cashBookList);
    setFilteredData2(cashBookList);
  }, [dispatch, cashBookList]);

  useEffect(() => {
    dispatch({
      type: REMOVE_ACCOUNT_DATA
    });

    dispatch(getCompanyConfigurtion());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAccounts());
  }, [currentAccountData]);

  const handleBlurAmt = () => {
    if (bookInfo.amount.length <= 3) {
      let amt = Number(bookInfo.amount / 1000).toFixed(3);
      setBookInfo({...bookInfo, amount: amt});
    } else {
      if (bookInfo.amount.toString().includes('.')) {
        setBookInfo({...bookInfo, amount: (+bookInfo.amount)?.toFixed(3)});
      } else {
        setBookInfo({...bookInfo, amount: (+bookInfo.amount / 1000).toFixed(3)});
      }
    }
  };

  const handleAmount = (value) => {
    if (value == '') {
      setBookInfo({...bookInfo, amount: ''});
    } else if (value >= 0) {
      const val = ('' + value).replace('.', '');
      if (val?.length > 3) {
        const suppressedValue = Number(+val / 1000).toFixed(3);
        setBookInfo({...bookInfo, amount: suppressedValue});
      } else {
        setBookInfo({...bookInfo, amount: Number(value)});
      }
    }
  };
  const handleDate = (e) => {
    if (e.length == 0) {
      setDate([new Date()]);
    } else {
      setDate(e);
    }
  };

  const ondownloadClick = (typedoc) => {
    dispatch(downloadCashBookList(moment(date[0]).format('MM/DD/YYYY'), type, filter, typedoc, cashStatus));
  };

  const handleConfirmDelete = () => {
    bookInfo.date = date[0];
    dispatch(deleteCashBook(bookInfo, filter, type));

    setModal(false);
    onclearValue()
  };
  const handleSaveBook = () => {
    setBookInfo({...bookInfo, errShow: true});
    handleValidate(true);
  };

  useEffect(() => {
    handleValidate();
  }, [bookInfo]);

  const handleValidate = (isSave) => {
    let msg = 'Please select';
    let obj = {...errorMsg};

    if (!bookInfo?.account?.value) {
      obj.account = msg + ' account';
    } else {
      obj.account = '';
    }
    if (!bookInfo?.narration) {
      obj.narration = msg + ' token';
    } else {
      obj.narration = '';
    }
    if (bookInfo?.account?.value && bookInfo?.narration) {
      obj.error = false;
    } else {
      obj.error = true;
    }
    if (isSave && !obj.error) {
      bookInfo.type = type;
      const userData = JSON.parse(localStorage.getItem('userData'));

      let data = {
        accountName: bookInfo.account.label,
        amount: bookInfo.amount ? bookInfo.amount : 0,
        accountID: bookInfo.account.value,
        narration: bookInfo?.narration,
        date: moment(date[0]).format('MM/DD/YYYY'),
        companyID: userData?.companyID,
        trans_type: type,
        cashStatus: cashStatus ? 1 : 2
      };

      if (bookInfo.id) {
        data.id = bookInfo.id;
        // data.cashStatus= cashStatus ? 1 : 2

      }
      dispatch(addCashbook(data, filter, onclearValue, type, fp, toggleTempUnlock));
    }
    setErrorMsg(obj);
  };

  const handleSearch = (e, key) => {
    e.preventDefault();
    // var deepCopy = JSON.parse(JSON.stringify(filteredData2));

    const result = filteredData2?.filter((a) => {
      if (a.trans_type == 'I') {
        a.type = 'inward';
      } else if (a.trans_type == 'O') {
        a.type = 'outward';
      }
      return (
        a.date?.toString()?.includes(key) ||
        a.amount?.toString()?.includes(key) ||
        a.accountName?.toString()?.toLowerCase()?.includes(key?.toLowerCase()) ||
        a.narration?.toString()?.includes(key.toLowerCase()) ||
        a.groupName?.toLowerCase()?.includes(key.toLowerCase()) ||
        a.trans_type?.includes(key)
      );
    });
    setFilteredData(result);
    setSearch(key);
  };

  let fd = useMemo(() => filteredData, [date, filteredData]);

  const handleEdit = useCallback((row, tempUnl) => {
    setTempUnlock(tempUnl);
    setType(row?.trans_type);
    setBookInfo({
      id: row.id,
      amount: Number(row.amount)?.toFixed(3),
      account: {label: row.accountName, value: row.accountID},
      narration: row.narration,
      errShow: true
    });
  }, []);

  const handleDelete = useCallback((row) => {
    setModal(true);
    setBookInfo({...bookInfo, id: row.id});
  }, []);

  return (
    <>
      <div className='page-content transactions-table-width'>
        <Container fluid={true}>
          <Breadcrumbs title='Cashbook - account-books' breadcrumbItem='Cashbook' />

          <Card>
            <CardBody>
              <Row className='mb-4 '>
                <Col sm='auto' className='mb-3'>
                  <Label>Type</Label>
                  <Nav pills className='navtab-bg nav-justified border mx-1 '>
                    <NavItem className={type == 0 ? 'bg-primary rounded ' : 'rounded'} onClick={() => setType(0)}>
                      <NavLink>
                        <span className={type == 0 ? 'text-white cursor' : 'cursor'}>All</span>
                      </NavLink>
                    </NavItem>

                    <NavItem className={type == 'I' ? 'bg-warning rounded ' : 'rounded'} onClick={() => setType('I')}>
                      <NavLink>
                        <span className={type == 'I' ? 'text-white cursor' : 'cursor'}>Inward</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className={type == 'O' ? 'bg-info rounded ' : 'rounded'} onClick={() => setType('O')}>
                      <NavLink>
                        <span className={type == 'O' ? 'text-white cursor' : 'cursor'}>Outward</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>

                <Col sm='auto' className='mb-3'>
                  <Label> Date</Label>
                  <Flatpickr
                    className='form-control customWidthBank'
                    openMenuOnFocus={false}
                    options={{
                      mode: 'single',
                      disableMobile: true,
                      dateFormat: 'd/m/Y',
                      altInput: true,
                      altFormat: 'd/m/Y',
                      openMenuOnFocus: true
                    }}
                    ref={fp}
                    value={date}
                    onChange={(e) => handleDate(e)}
                  />
                </Col>
                <Col sm='auto' className='mb-3'>
                  <Label>Token</Label>
                  <Input
                    placeholder='Token'
                    className='w200 '
                    value={bookInfo.narration}
                    onChange={(e) => setBookInfo({...bookInfo, narration: e.target.value})}
                  />{' '}
                  {bookInfo.errShow ? <span className='text-error'> {errorMsg?.narration}</span> : <></>}
                </Col>
                <Col sm='auto' className='mb-3'>
                  <Label> Account</Label>
                  <Select
                    placeholder='Account'
                    className=' w200'
                    options={accResult?.accounts}
                    openMenuOnFocus={true}
                    // value={bookInfo?.account}
                    defaultValue={bookInfo?.account}
                    value={
                      accResult?.accounts && bookInfo?.account?.value
                        ? accResult?.accounts.find((option) => option.id == bookInfo?.account?.value)
                        : bookInfo?.account
                    }
                    noOptionsMessage={() => {
                      return (
                        <Button color='primary' onClick={() => tog_invoice(true)}>
                          Add New Account
                        </Button>
                      );
                    }}
                    onChange={(e) => {
                      const filteredDataVal = accResult?.accounts?.find((option) => option?.accountCode == newAccountDataVal?.accountCode);
                      const newVal = newAccountDataVal?.accountName ? filteredDataVal : e;
                      setBookInfo({...bookInfo, account: newVal});
                    }}
                  />
                  {bookInfo.errShow ? <span className='text-error'> {errorMsg?.account}</span> : <></>}
                </Col>
                <Col sm='auto' className='mb-3'>
                  <Label>Amount</Label>
                  <input
                    className='form-control customWidthBank'
                    type='number'
                    placeholder='Amount'
                    value={bookInfo.amount}
                    onBlur={handleBlurAmt}
                    onChange={(e) => handleAmount(e.target.value)}
                  />
                  {bookInfo.errShow ? <span className='text-error'> {errorMsg?.amount}</span> : <></>}
                </Col>

                <Col lg='auto' className='mb-3'>
                  <div className='mt30'>
                    {cashLock && rowLock && !tempUnlock && type != 0 ? (
                      <Button color='primary' className='mx-1 ' onClick={(e) => handleOpenUnlock(e, 'row')}>
                        <i className='bx bx-lock  font-size-20'></i>{' '}
                      </Button>
                    ) : (
                      type != 0 && (
                        <Button color='primary' onClick={handleSaveBook} className='mx-1 '>
                          {bookInfo.id ? <i className='bx bx-pencil  font-size-20'></i> : <i className='bx bx-plus  font-size-20 '></i>}
                        </Button>
                      )
                    )}

                    <Button color='primary' className='mx-1' onClick={() => onclearValue()}>
                      <i className='bx bx-reset font-size-20'></i>
                    </Button>
                  </div>
                </Col>
              </Row>

              <div className='row2 mb-3'>
                <div className='row0'>
                  <Input
                    placeholder={`(${filteredData?.length}) Records...`}
                    className='searchBar mx-2'
                    value={search}
                    onChange={(e) => handleSearch(e, e.target.value)}
                  />
                  {filteredData?.length > 0 ? (
                    <>
                      <Button
                        className='mx-1'
                        onClick={() => {
                          ondownloadClick('pdf');
                        }}
                      >
                        PDF
                        {/* <i className='bx bxs-download font-size-12'>PDF</i> */}
                      </Button>
                      <Button
                        // color='primary'
                        className='mx-1'
                        onClick={() => {
                          ondownloadClick('csv');
                        }}
                      >
                        CSV
                        {/* <i className='bx bxs-download font-size-12'>CSV</i> */}
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {cashStatus ? (
                  <div className='row0 '>
                    <Nav pills className='navtab-bg nav-justified border mx-1 '>
                      <NavItem className={filter == 0 ? 'bg-primary rounded ' : 'rounded'} onClick={() => setFilter(0)}>
                        <NavLink>
                          <span className={filter == 0 ? 'text-white cursor' : 'cursor'}>All</span>
                        </NavLink>
                      </NavItem>
                      <NavItem className={filter == 2 ? 'bg-success  rounded ' : 'rounded'} onClick={() => setFilter(2)}>
                        <NavLink>
                          <span className={filter == 2 ? 'text-white cursor' : 'cursor'}>Approved</span>
                        </NavLink>
                      </NavItem>
                      <NavItem className={filter == 1 ? 'bg-warning rounded ' : 'rounded'} onClick={() => setFilter(1)}>
                        <NavLink>
                          <span className={filter == 1 ? 'text-white cursor' : 'cursor'}>Pending</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className={filter == 3 ? 'bg-danger rounded ' : 'rounded'} onClick={() => setFilter(3)}>
                        <NavLink>
                          <span className={filter == 3 ? 'text-white cursor' : 'cursor'}>Deleted</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              <div id='table-invoices-list'>
                {filteredData?.length > 0 ? (
                  <CB_Table
                    tableData={fd}
                    filter={filter}
                    type={type}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleOpenUnlock={handleOpenUnlock}
                    tableLock={tableLock}
                    cashLock={cashLock}
                    cashStatus={cashStatus}
                  />
                ) : (
                  <div className='text-center mt-5 mb-4 noDAtaA'>No Data Available</div>
                )}
              </div>

              <div className='mt-3 text-end'></div>
            </CardBody>
          </Card>
        </Container>
        <div className='whiteBox'></div>
      </div>

      <>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}>Delete CashBook </ModalHeader>
          <ModalBody>Are you sure to delete this CashBook ?</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={() => handleConfirmDelete()}>
              Delete
            </Button>
            <Button color='secondary' onClick={() => toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <TransactionPassword
          modalpassword={unlockModal}
          togglePassword={(val) => toggleUnlockModal(val)}
          cashbook={true}
          compType='Cashbook'
          lockType={lockType}
          handleUnlock={handleUnlock}
        />

        <AccountModal
          // isParty={party}
          modal_invoice={modal_invoice}
          tog_invoice={() => tog_invoice()}
          setmodal_invoice={() => setmodal_invoice()}
          setNewAccountData={(data) => setNewAccountDataVal(data)}
        />
      </>
    </>
  );
};
export default CashB;
