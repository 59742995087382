import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {downloadLedgerList, downloadReconciliationList, getLedgerList, getReconciliationList} from '../../../store/Ledger/actions';
import {toast} from 'react-toastify';
import moment from 'moment';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import {REMOVE_LEADER_DATA} from '../../../store/City/actionTypes';
import {getAccounts, loaderStop} from '../../../store/actions';

const reconciliationFilterOptions = [
  {label: 'All', value: 0},
  {label: 'Reconciled', value: 1},
  {label: 'Not reconciled', value: 2}
];

function LeaderOperations(props) {
  const [account, setAccount] = useState('');
  const [reconciliationFilter, setReconciliationFilter] = useState({label: 'All', value: 0});
  let prevDate = moment().subtract(6, 'day');
  const [fileName, setFileName] = useState();
  const [date, setDate] = useState([prevDate._d, new Date()]);
  const dispatch = useDispatch();
  const {ledgerResult, narration, setNarration} = props;

  const {accounts} = useSelector((state) => ({
    accounts: state.accounts.accounts
  }));
  const handleAccount = (e) => {
    setAccount(e);
    handleGetReport(date, e, reconciliationFilter);
    props?.setReconciliationDate({date: date, accId: e?.value, recon: reconciliationFilter?.value});
  };

  const handleReconciliation = (e) => {
    if (!account?.value) {
      toast.info('Please select the account');
      return;
    } else {
      setReconciliationFilter(e);
      handleGetReport(date, account, e);
      props?.setReconciliationDate({date: date, accId: account?.value, recon: e?.value});
    }
  };
  const handleGetReport = (date, acc, reconciliation) => {
    dispatch(getReconciliationList(date, acc?.value, reconciliation?.value || 0));
  };
  useEffect(() => {
    handleReload();
    return () => {
      setAccount('');
      dispatch({
        type: REMOVE_LEADER_DATA
      });
    };
  }, []);
  const handleReload = () => {
    dispatch(getAccounts());
    props?.setReconciliationDate({date: date, accId: account?.value, recon: reconciliationFilter?.value});
    if (!account?.value) {
      // dispatch(loaderStop());
      return;
    } else {
      dispatch(getReconciliationList(date, account.value, reconciliationFilter?.value || 0));
    }
  };
  const ondownloadClick = (type) => {
    if (!account?.value) {
      toast.info('Please select account');
    } else {
      const narrations = narration ? 1 : 0;
      dispatch(
        downloadReconciliationList(date, account?.value, narrations, fileName, type, account?.label, reconciliationFilter?.value || 0)
      );
      setFileName('');
    }
  };

  const handleDate = (e) => {
    setDate(e);
    props?.setReconciliationDate({date: e, accId: account?.value, recon: reconciliationFilter?.value});
    if (e.length > 1) {
      if (!account?.value) {
        toast.info('Please select the account');
        return;
      }
      handleGetReport(e, account, reconciliationFilter);
    }
  };

  //method overloading handleGetReport()
  const handleDateClose = (e) => {
    if (date.length != 2) {
      let defDate = [e[0], e[0]];
      setDate(defDate);
      // if (!account?.value) {
      //   toast.info('Please select the account');
      //   return;
      // }
      // handleGetReport(defDate, account);
    }
  };

  return (
    <div className='position-relative'>
      <div>
        <Row>
          <Col className='mt-3 auto col-md-4'>
            <div className='d-flex gap-2'>
              <Input
                className='me-2 w-50'
                type='text'
                value={fileName}
                onChange={(e) => setFileName(e?.target?.value)}
                placeholder='Enter export file name...'
              />
              <Button onClick={() => ondownloadClick('pdf')}>PDF</Button> &nbsp;
              <Button onClick={() => ondownloadClick('csv')}> CSV</Button> &nbsp;
              <Button
                style={{
                  background: 'none',
                  color: '#495057',
                  border: '1px solid #eff0f2'
                }}
                onClick={() => handleReload()}
                disabled={!account?.value}
              >
                <span className='bx bx-revision font-size-18'></span>
              </Button>{' '}
            </div>
          </Col>
          <Col className='mt-3 auto col-md-6"' md='6' sm='8'>
            <div className='row'>
              <div className='col-md-4'>
                <Flatpickr
                  className='form-control w-100 mb-3'
                  placeholder='Date Range'
                  value={date}
                  options={{
                    mode: 'range',
                    disableMobile: true,
                    dateFormat: 'd/m/Y '
                  }}
                  onChange={(e) => handleDate(e)}
                  onClose={(e) => handleDateClose(e)}
                />
              </div>
              <div className='col-md-3'>
                <Select
                  className='w-100'
                  openMenuOnFocus={true}
                  placeholder='Select Account'
                  options={accounts}
                  value={account}
                  onChange={(e) => handleAccount(e)}
                />
              </div>
              <div className='col-md-3'>
                <Select
                  className='w-100'
                  openMenuOnFocus={true}
                  placeholder='Select Reconciliation'
                  options={reconciliationFilterOptions}
                  value={reconciliationFilter}
                  onChange={(e) => handleReconciliation(e)}
                />
              </div>
              <div className='col-md-2'>
                <Input
                  className='me-2'
                  type='checkbox'
                  checked={narration}
                  id='checkbox_id'
                  onChange={(e) => setNarration(e.target.checked)}
                />
                <Label for='checkbox_id' className='me-4  cursor'>
                  Narration
                </Label>
              </div>
            </div>
          </Col>
          <Col className='mt-3 auto mb-3'>
            {ledgerResult.ledgerList?.totalAmount && (
              <span className='mt-2 fw-bold'>
                <span className='me-2'>Total Balance : </span>
                <span
                  className={ledgerResult.ledgerList?.drAmount - ledgerResult.ledgerList?.crAmount > 0 ? 'text-danger' : 'text-success'}
                >
                  {ledgerResult.ledgerList?.totalAmount}
                </span>
              </span>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LeaderOperations;
