import React, {useEffect, useState} from 'react';
import TableContainer from '../../../components/Common/ReportWithoutPagination';
import moment from 'moment';

const GroupTable = (props) => {
  const [unit, setUnit] = useState('');

  const columns = [
    {
      Header: 'Tran No',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.tranNo}</div>;
      },
      Filter: false
    },
    {
      Header: 'Date',
      Cell: (cell) => {
        return (
          <div>
            {moment(cell?.cell?.row?.original.tranDate).format('DD/MM/YYYY') === '01/01/1900'
              ? ''
              : moment(cell?.cell?.row?.original.tranDate).format('DD/MM/YYYY')}
          </div>
        );
      }
    },

    {
      Header: 'Type',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.tranType}</div>;
      },
      Filter: false
    },
    {
      Header: 'Credit (GM)',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.credit == 0 ? null : cell?.cell?.row?.original.credit}</div>;
      },
      Filter: false
    },
     {
      Header: 'Debit (GM)',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.debit == 0 ? null : cell?.cell?.row?.original.debit}</div>;
      },
      Filter: false
    },
    
    {
      Header: 'Rate',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.rate == 0 ? null : cell?.cell?.row?.original.rate}</div>;
      },
      Filter: false
    },

    {
      Header: 'Amount',
      Cell: (cell) => {
        return <div>{cell?.cell?.row?.original.amount == 0 ? null : cell?.cell?.row?.original.amount}</div>;
      },
      Filter: false
    }
  ];

  useEffect(() => {
    let arr = props?.tableData?.filter((a) => a.unitName);
    if (arr) {
      setUnit(arr[0]?.unitName);
    }
  }, [props?.tableData]);
  return (
    <>
      {props?.tableData && (
        <TableContainer
          columns={columns}
          data={props.tableData}
          isGlobalFilter={false}
          customPageSize={20}
          tableName={'stockTable'}
          tableDataExtra={props?.tableDataExtra}
          unit={props?.tableData}
        />
      )}
    </>
  );
};

export default GroupTable;
