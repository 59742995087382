import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  UNBLOCK_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  COMPANY_SUCCESS,
  FORGET_USER_SUCCESS,
  COMAPNY_API_ERROR,
  MENU_BY_ROLE,
  FORGET_USER_DONE
} from './actionTypes';

const initialState = {
  error: '',
  company: [],
  menuList: [],
  user: '',
  companyError: '',
  loading: false,
  unBlockUser: {}
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        error: ''
      };
      break;
    case LOGIN_SUCCESS:
      localStorage.setItem('userData', JSON.stringify(action.payload));
      state = {
        ...state,
        loading: false,
        user: action.payload
      };
      break;
    case UNBLOCK_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        unBlockUser: action.payload
      };
      break;
    case FORGET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: 'Verification email send on register email please verify once'
      };
      break;
    case FORGET_USER_DONE:
      state = {
        ...state,
        loading: false,
        error: ''
      };
      break;
    case COMPANY_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        company: action.payload
      };
      break;
    case COMAPNY_API_ERROR:
      state = {
        ...state,
        loading: false,
        error: '',
        companyError: action.payload
      };
      break;
    case LOGOUT_USER:
      state = {...state, isUserLogout: true};
      break;
    case LOGOUT_USER_SUCCESS:
      state = {...state, isUserLogout: true};
      break;
    case MENU_BY_ROLE:
      state = {...state, menuList: action.payload};
      break;

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false
      };
      break;
    default:
      state = {...state};
      break;
  }
  return state;
};

export default login;
