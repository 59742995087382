/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table, Row} from 'reactstrap';
import {useEffect, useState} from 'react';
import moment from 'moment';

import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';

import {downloadBBComm, saveComm, deleteComm} from '../../store/actions';

const Comm = ({type, bookInfo, modalConfig, togModalConfig, onclearValue, date, party2}) => {
  const dispatch = useDispatch();
  const [commList, setCommList] = useState([]);
  const bookReducer = useSelector((state) => state.bankBookReducer);
  const [delList, setDelList] = useState([]);

  useEffect(() => {
    let arr = [...bookReducer.commList];
    arr?.map((a) => {
      a.amt = !a.isSplit && !a.commissionAmount ? Number(a.amount).toFixed(3) : Number(a.commissionAmount).toFixed(3);
      a.rate = Number(a.commissionRate);
      a.comm = Number(a.commission)?.toFixed(3);
      a.isParent = a.isSplit ? false : true;
      a.pAmt = Number(a.amount);
    });
    handleVerify(arr);
  }, [bookReducer.commList]);

  const handleRowClear = (e, i) => {
    const obj = [...commList];
    obj[i].amt = '0.000';
    obj[i].rate = 0;
    obj[i].comm = '0.000';
    handleVerify(obj);
  };

  const handleAmt2 = (e, i) => {
    const obj = [...commList];
    let value = e.target.value;
    if (value == '') {
      obj[i].amt = '';
      obj[i].comm = (0).toFixed(3);
    } else if (value >= 0) {
      const val = ('' + value).replace('.', '');
      if (val?.length > 3) {
        const suppressedValue = Number(+val / 1000).toFixed(3);
        obj[i].amt = suppressedValue;
        obj[i].comm = Number((obj[i].rate * obj[i].amt) / 100000).toFixed(3);
      } else {
        obj[i].comm = Number((obj[i].rate * obj[i].amt) / 100000).toFixed(3);
        obj[i].amt = Number(value);
      }
    }
    handleVerify(obj);
    // setCommList(obj);
  };
  const handleBlurAmt2 = (e, i, row) => {
    const obj = [...commList];
    if (obj[i].amt.length <= 3) {
      obj[i].amt = Number(obj[i].amt / 1000).toFixed(3);
      obj[i].comm = Number((obj[i].rate * obj[i].amt) / 100000).toFixed(3);
    } else {
      if (obj[i].amt.toString().includes('.')) {
        obj[i].amt = Number(+obj[i].amt).toFixed(3);
      } else {
        obj[i].amt = Number(+obj[i].amt / 1000).toFixed(3);
      }
    }
    setCommList(obj);
    handleVerify(obj);
  };

  const handleRate = (e, i) => {
    const obj = [...commList];
    let value = e.target.value;
    if (value == '') {
      obj[i].rate = '';
    } else if (value) {
      obj[i].rate = Number(value);
      obj[i].comm = ((Number(obj[i].amt) * obj[i].rate) / 100000).toFixed(3);
    }
    setCommList(obj);
  };
  const handleBlurRate = (e, i) => {
    const obj = [...commList];
    obj[i].rate = Number(obj[i].rate);
    obj[i].comm = ((Number(obj[i].amt) * obj[i].rate) / 100000).toFixed(3);
    setCommList(obj);
  };

  const handleComm = (e, i) => {
    const obj = [...commList];
    let value = e.target.value;
    if (value == '') {
      obj[i].comm = '';
      obj[i].rate = 0;
    } else if (value) {
      const val = ('' + value).replace('.', '');
      if (val?.length > 3) {
        const suppressedValue = Number(+val / 1000).toFixed(3);
        obj[i].comm = suppressedValue;
        obj[i].rate = Number((obj[i].comm * 100000) / Number(obj[i].amt));
      } else {
        obj[i].comm = Number(value);
        obj[i].rate = Number((obj[i].comm * 100000) / Number(obj[i].amt));
      }
    }
    setCommList(obj);
  };

  const handleBlurComm = (e, i) => {
    const obj = [...commList];
    if (obj[i].comm?.length <= 3) {
      obj[i].comm = Number(obj[i].comm / 1000).toFixed(3);
      obj[i].rate = Number((obj[i].comm * 100000) / Number(obj[i].amt));
    } else {
      if (obj[i].comm.toString().includes('.')) {
        obj[i].comm = Number(+obj[i].comm).toFixed(3);
      } else {
        obj[i].comm = Number(+obj[i].comm / 1000).toFixed(3);
      }
    }
    setCommList(obj);
  };

  const onClearCommission = () => {
    setCommList([]);
    togModalConfig();
    // onclearValue();
    setDelList([]);
  };

  const handleSaveComm = () => {
    bookInfo.date = date[0];
    bookInfo.type = type;
    handleVerify(commList, true);
  };

  const handleAddRow = (e, i, row) => {
    let calAmt = Number(row.pAmt) - Number(row.sum).toFixed(3);
    let arr = [...commList];
    let obj = {...row};
    obj.id = 0;
    obj.isParent = false;
    obj.amt = calAmt.toFixed(3);
    obj.rate = 0;
    obj.comm = '0.000';
    obj.isSplit = true;

    //index,replacing,obj
    arr.splice(i + 1, 0, obj);

    handleVerify(arr);
  };

  const handleRowDelete = (e, i, row) => {
    let obj = [...commList];
    obj.splice(i, 1);
    handleVerify(obj);
    setTimeout(() => {
      if (row.id != 0) {
        row.isDeleted = 1;
        setDelList([...delList, row]);
        // dispatch(deleteComm(row.id));
      }
    }, 300);
  };

  const handleVerify = (obj1, isSave) => {
    const obj = [...obj1];
    let arr = obj.filter((a) => a.partyAccountID && a.isParent);
    let abc = [];

    arr?.map((b) => {
      let sum = 0;
      obj?.map((a) => {
        if (b.partyAccountID == a.partyAccountID) {
          sum += Number(a.amt);
        }
      });
      let ob1 = {sum: sum, partyAccountName: b.partyAccountName, pAmt: b.pAmt};
      abc.push(ob1);
    });

    setTimeout(() => {
      obj.map((a) => {
        abc?.map((b) => {
          if (b.partyAccountName == a.partyAccountName && a.isParent) {
            a.sum = b.sum;
          }
          if (b.partyAccountName == a.partyAccountName && !a.isParent) {
            a.sum = b.sum;
          }
        });
      });
      setCommList(obj);
    });

    let err = abc.some((a) => (+a.sum).toFixed(3) > a.pAmt);
    if (!err && isSave) {
      dispatch(saveComm(commList, bookInfo, onClearCommission, delList));
    }
  };

  const ondownloadClick = (typedoc) => {
    dispatch(downloadBBComm(moment(date[0]).format('MM/DD/YYYY'), typedoc, type, party2));
  };

  return (
    <>
      <Modal isOpen={modalConfig} toggle={onClearCommission} size='xl' backdrop={'static'}>
        <ModalHeader toggle={onClearCommission}>Commission</ModalHeader>
        <ModalBody>
          {commList?.length > 0 ? (
            <div className='table-responsive react-table '>
              <Table cellPadding={2} border={1}>
                <thead>
                  <tr>
                    <td className='w150'>Party A/C</td>
                    <td>Total Amount</td>
                    <td>Amount</td>
                    <td>Rate</td>
                    <td>Commission</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {commList &&
                    commList?.map((a, i) => {
                      return (
                        <tr>
                          <td className={a?.isParent ? 'FBlue' : ''}>
                            <div> {a.partyAccountName}</div>
                          </td>
                          <td>{a.amount}</td>
                          <td>
                            <Input
                              type='number'
                              className='w250'
                              value={a.amt}
                              onChange={(e) => handleAmt2(e, i, a)}
                              onBlur={(e) => handleBlurAmt2(e, i, a)}
                            />
                            {(+a.sum).toFixed(3) > a.pAmt && a.isParent && (
                              <span className='text-error'>Amount can't be greater than total amount</span>
                            )}
                          </td>
                          <td>
                            <Input
                              type='number'
                              className='w150'
                              value={a.rate}
                              onBlur={(e) => handleBlurRate(e, i)}
                              onChange={(e) => handleRate(e, i )}
                            />
                          </td>
                          <td>
                            <Input
                              disabled={Number(a.amt) > 0 ? false : true}
                              type='number'
                              className='w150'
                              value={a.comm}
                              onBlur={(e) => handleBlurComm(e, i)}
                              onChange={(e) => handleComm(e, i)}
                            />
                          </td>

                          <td className=' tdIcon'>
                            <div className='w90'>
                              {(+a.sum).toFixed(3) > a.pAmt ? (
                                <i className='bx bx-plus  font-size-18 text-danger'></i>
                              ) : (
                                <i className='bx bx-plus  font-size-18 cursor ' onClick={(e) => handleAddRow(e, i, a)}></i>
                              )}
                              <i className='bx bx-reset font-size-18 cursor ' onClick={(e) => handleRowClear(e, i, a)}></i>
                              {!a?.isParent || a?.isDel ? (
                                <i
                                  className='bx bx-trash-alt text-danger font-size-18 cursor'
                                  onClick={(e) => handleRowDelete(e, i, a)}
                                ></i>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className='text-center mt-3 mb-3'>No record found</div>
          )}
        </ModalBody>

        <div className='row2 mb-3 px-3'>
          {commList?.length > 0 ? (
            <div>
              {' '}
              <Button
                className='mx-1'
                onClick={() => {
                  ondownloadClick('pdf');
                }}
              >
                PDF
              </Button>
              <Button
                className='mx-1'
                onClick={() => {
                  ondownloadClick('csv');
                }}
              >
                CSV
              </Button>
            </div>
          ) : (
            <></>
          )}
          <div>
            {' '}
            {commList?.length > 0 ? (
              <Button color='primary' className='mx-1' onClick={() => handleSaveComm()}>
                Save
              </Button>
            ) : (
              <></>
            )}
            <Button color='secondary' className='mx-1' onClick={() => onClearCommission()}>
              Cancel
            </Button>{' '}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Comm;
