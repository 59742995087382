import React, {useState, useEffect, memo} from 'react';
import {Col, Input, Row, Table} from 'reactstrap';
import moment from 'moment';
import {ApproveBankbook} from '../../store/actions';
import {useDispatch} from 'react-redux';
const BB_Table = ({
  bbres,
  tableData,
  isImage,
  handleEdit,
  handleDelete,
  filter,
  type,
  handleOpenUnlock,
  tableLock,
  bankLock,
  bankStatus
}) => {
  const dispatch = useDispatch();

  const handleApprove = (row) => {
    dispatch(ApproveBankbook(row, filter, type));
  };
  return (
    <>
      <Row className='mt-4 '>
        <Col sm={12}>
          <Table
            responsive
            variant='dark'
            className='
              table-bordered'
          >
            <thead>
              <tr style={{background: '#F5F6F8'}}>
                <th>Created date </th>
                <th>Bank Account </th>
                <th>Party Account</th>
                <th>Description</th>
                <th>Amount </th>
                <th>UTR</th>
                <th>Type</th>
                {bankStatus ? <th>Status</th> : <></>}
                <th className={isImage ? 'd-none' : ''}>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData?.map((row, i) => (
                  <>
                    <tr key={row.id} className={row.status == 'Deleted' && 'deleted'}>
                      <td>{moment(row?.createdDate).format('DD/MM/YYYY')}</td>
                      <td>{row?.bankAccountName}</td>
                      <td>{row?.partyAccountName}</td>
                      <td>{row?.description}</td>
                      <td>{Number(row?.amount)?.toFixed(3)}</td>
                      <td>{row?.narration}</td>
                      <td>{row?.trans_type == 'I' ? 'Inward' : 'Outward'}</td>
                      {bankStatus ? (
                        <td>
                          {row.status == 'Deleted' && (
                            <span className='cursor badge badge-pill badge-soft-danger font-size-12'>Deleted</span>
                          )}

                          {row.status == 'Pending' && (
                            <span className='cursor badge badge-pill badge-soft-warning font-size-12' onClick={() => handleApprove(row)}>
                              Pending
                            </span>
                          )}

                          {row.status == 'Approved' && <span className='cursor badge badge-pill badge-soft-success font-size-12'>Ok</span>}
                        </td>
                      ) : (
                        <></>
                      )}

                      {!bankLock || !tableLock || !row.isLock ? (
                        <td className={isImage ? 'd-none' : ''}>
                          {row.status == 'Deleted' ? (
                            <></>
                          ) : (
                            <>
                              <span
                                className=' cursor text-primary mx-1'
                                onClick={() => {
                                  handleEdit(row, true);
                                }}
                              >
                                <i className='bx bx-pencil font-size-18'></i>
                              </span>
                              <span
                                className=' cursor text-danger'
                                onClick={() => {
                                  handleDelete(row);
                                }}
                              >
                                <i className='bx bx-trash-alt font-size-18'></i>
                              </span>
                            </>
                          )}
                        </td>
                      ) : (
                        <td className={isImage ? 'd-none' : ''}>
                          {!(row.status == 'Deleted') && (
                            <i className='bx bx-lock  font-size-20 cursor text-primary' onClick={(e) => handleOpenUnlock(e, 'both')}></i>
                          )}
                        </td>
                      )}
                      {/* <td className={isImage ? 'd-none' : ''}>
                        {row.status == 'Deleted' ?<></>: (
                          <>
                            <span
                              className=' cursor text-primary'
                              onClick={() => {
                                handleEdit(row);
                              }}
                            >
                              <i className='bx bx-pencil font-size-18 '></i>
                            </span>
                            <span
                              className=' cursor text-danger'
                              onClick={() => {
                                handleDelete(row);
                              }}
                            >
                              <i className='bx bx-trash-alt font-size-18'></i>
                            </span>
                          </>
                        )}
                      </td> */}
                    </tr>
                  </>
                ))}
              <tr style={{background: '#F5F6F8', fontWeight: '500'}}>
                <td className='alignRight' colSpan={4}>
                  Total
                </td>
                <td className='row2'>{bbres?.totalAmount}</td>
                <td colSpan={5}></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
export default memo(BB_Table);
