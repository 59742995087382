import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useTable, useGlobalFilter, useSortBy, useFilters, useExpanded} from 'react-table';
import {Table} from 'reactstrap';
import {DefaultColumnFilter} from './filters';

// Define a default UI for filtering

const TableContainerWithOutPagination = ({
  columns,
  data,
  customPageSize,
  className,
  tableName,
  tableDataExtra,
  customPageSizeOptions,
  unit,
  totalCountDisplay,
  otherProps
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows, // Use 'rows' instead of 'page'
    prepareRow,

    state: {globalFilter}
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {Filter: DefaultColumnFilter},
      initialState: {
        pageSize: customPageSize
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded
    // Remove usePagination
  );

  return (
    <Fragment>
      {rows?.length > 0 ? (
        <div className={`table-responsive react-table leaderSection ${className != 'Invoice table1' && 'mt-4'}`}>
          <Table bordered hover {...getTableProps()} className={className}>
            <thead className='table-light table-nowrap'>
              {headerGroups.map((headerGroup, index) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} className={index === 0 ? 'leaderSticky' : 'leaderSticky1'}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th {...column.getHeaderProps()} className={column?.headerClassName}>
                        {column.canGroupBy ? (
                          // If the column can be grouped, let's add a toggle
                          <span {...column.getGroupByToggleProps()} />
                        ) : null}
                        {column.render('Header')}{' '}
                        {column?.Header === 'Quantity' && <span>({column?.filteredRows[0]?.original?.unitName})</span>}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows?.length > 0 ? (
                rows?.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              {...cell.getCellProps()}
                              className={row?.original?.isDeleted && cell?.column?.Header !== 'Date' && 'deleted'}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })
              ) : (
                <tr className='text-center'>
                  <p>No Data Available</p>
                </tr>
              )}
              {rows?.length > 0 && tableName == 'stockTable' && (
                <>
                  <tr className='leaderStickyBottom'>
                    <td className='text-end' colSpan={3}>
                      Total :
                    </td>
                    <td>{tableDataExtra?.totalCredit}</td>
                    <td>{tableDataExtra?.totalDebit}</td>
                    <td></td>
                    <td>{tableDataExtra?.totalAmount}</td>
                  </tr>
                </>
              )}
              {rows?.length > 0 && tableName == 'partyStock' && (
                <>
                  <tr className='leaderStickyBottom'>
                    <td className='text-end' colSpan={2}>
                      Total:-
                    </td>
                    <td>{tableDataExtra?.totalQty}</td>
                    <td></td>
                    <td></td>
                    <td className=''>{tableDataExtra?.totalAmount}</td>
                  </tr>
                </>
              )}
              {rows?.length > 0 && totalCountDisplay && (
                <>
                  <tr className='leaderStickyBottom'>
                    <td className='text-end' colSpan={2}>
                      Total:
                    </td>
                    <td>{otherProps?.tableData?.totalOpDrBalance}</td>
                    <td>{otherProps?.tableData?.totalOpCrBalance}</td>
                    <td>{otherProps?.tableData?.totalTranDrBalance}</td>
                    <td>{otherProps?.tableData?.totalTranCrBalance}</td>
                    <td>{otherProps?.tableData?.totalCIDrAmount}</td>
                    <td>{otherProps?.tableData?.totalCICrAmount}</td>
                    {/* <td className=''>{tableDataExtra?.totalAmount}</td> */}
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className='text-center p-5'>No Data Available</div>
      )}
    </Fragment>
  );
};

TableContainerWithOutPagination.propTypes = {
  preGlobalFilteredRows: PropTypes.any
};

export default TableContainerWithOutPagination;
