import {
  LEDGER_FETCH_SUCCESS,
  LEDGER_FETCH_ERROR,
  REMOVE_LEADER_DATA,
  LEADER_TRANSACTION_DATA,
  RECONCILIATION_FETCH_SUCCESS
} from './actionTypes';

const initialState = {
  error: '',
  ledgerList: [],
  loading: false,
  refresh: false,
  LeaderEntry: []
};

const ledgerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEDGER_FETCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        ledgerList: action.payload
      };
      break;
    case RECONCILIATION_FETCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        reconciliationList: action.payload
      };
      break;
    case LEDGER_FETCH_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload
      };
      break;
    case REMOVE_LEADER_DATA:
      state = {
        ...state,
        ledgerList: []
      };
      break;
    case LEADER_TRANSACTION_DATA:
      state = {
        ...state,
        LeaderEntry: action.payload
      };
      break;

    default:
      state = {...state};
      break;
  }
  return state;
};

export default ledgerReducer;
