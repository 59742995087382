import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination} from 'react-table';
import {Table, Row, Col, Button, Input} from 'reactstrap';
import {Filter, DefaultColumnFilter} from './filters';

// Define a default UI for filtering
function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm={3}>
      <div className='search-box me-2 mb-2 d-inline-block'>
        <div className='position-relative'>
          <label htmlFor='search-bar-0' className='search-label'>
            <span id='search-bar-0-label' className='sr-only'>
              Search this table
            </span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id='search-bar-0'
              type='text'
              className='form-control'
              placeholder={`${count} records...`}
              value={value || ''}
            />
          </label>
          <i className='bx bx-search-alt search-icon'></i>
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
  columns,
  data,

  customPageSize,
  className,
  isFilterIs,
  extradata,
  tableDataExtra,
  customPageSizeOptions,
  isReconciliation
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows, // Use 'rows' instead of 'page'
    prepareRow
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {Filter: DefaultColumnFilter},
      initialState: {
        pageSize: customPageSize
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded
    // Remove usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  return (
    <Fragment>
      <Row className='mb-2'>
        {/* {isGlobalFilter && (
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
        )} */}
      </Row>
      {rows?.length > 0 ? (
        <div className='table-responsive react-table leaderSection'>
          <Table bordered hover {...getTableProps()} className={className}>
            <thead className='table-light table-nowrap'>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} className='leaderSticky'>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id}>
                      <div className='mb-2' {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {generateSortingIndicator(column)}
                      </div>
                      <Filter column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.length > 0 && <></>}
              {rows.length > 0 ? (
                rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr className={row?.original?.isDeleted ? 'deleted' : row?.original?.tranID == 0 ? 'leaderSticky1' : '  '}>
                        {row.cells.map((cell) => {
                          return (
                            <td className={cell?.row?.original?.isCommEntry === 1 && 'bg-light'} key={cell.id} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })
              ) : (
                <tr className='text-center'>
                  <p>No Data Available</p>
                </tr>
              )}
              {rows.length > 0 && (
                <>
                  <tr className='leaderStickyBottom'>
                    <td className='text-end' colSpan={isFilterIs ? 4 : 3}>
                      Total :
                    </td>
                    <td> {tableDataExtra?.totalBorokerage} </td>
                    <td> {tableDataExtra?.crAmount} </td>
                    <td> {tableDataExtra?.drAmount} </td>
                    <td>
                      {' '}
                      {/* {tableDataExtra?.totalAmount} <span></span> */}
                      {rows[rows.length - 1]?.original?.balance}
                    </td>
                    {isReconciliation && <td></td>}
                  </tr>
                </>
              )}
              {/* {page.length > 0 && page.length} */}
            </tbody>
          </Table>
        </div>
      ) : (
        <p className='text-center p-5'>No Data Available</p>
      )}

      {/* {page.length > 0 && (
        <Row className='justify-content-md-end justify-content-center align-items-center'>
          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button color='primary' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </Button>
              <Button color='primary' onClick={previousPage} disabled={!canPreviousPage}>
                {'<'}
              </Button>
            </div>
          </Col>
          <Col className='col-md-auto d-none d-md-block'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length == 0 ? 1 : pageOptions.length}
            </strong>
          </Col>
          <Col className='col-md-auto'>
            <Input
              type='number'
              min={1}
              style={{width: 70}}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                {'>'}
              </Button>
              <Button color='primary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </Button>
            </div>
          </Col>
        </Row>
      )} */}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any
};

export default TableContainer;
