const SidebarData = [
  {
    label: 'Menu',
    isMainMenu: true
  },
  {
    label: 'Transactions',
    icon: 'bx bx-wallet icon nav-icon',
    url: '/transactions',
    bgcolor: 'bg-success'
  },

  {
    label: 'Cashbook',
    icon: 'bx bx-book-open icon nav-icon',
    url: '/cashbook',
    bgcolor: 'bg-success'
  },
  {
    label: 'Bankbook',
    icon: 'bx bxs-bank icon nav-icon',
    url: '/bankbook',
    bgcolor: 'bg-success'
  },
  {
    label: 'Journals',
    icon: 'bx bx-food-menu icon nav-icon',
    url: '/journals',
    bgcolor: 'bg-success'
  },
  // {
  //   label: 'ProfitLoss',
  //   icon: 'bx bx-money icon nav-icon',
  //   url: '/master/profitloss',
  //   bgcolor: 'bg-success'
  // },
  {
    label: 'Purchase',
    icon: 'bx bx-purchase-tag-alt  icon nav-icon',
    subItem: [
      {sublabel: 'Metal Order', link: '/purchase/metal/order'},
      {sublabel: 'Metal Invoice', link: '/purchase/metal/invoice'},
      {sublabel: 'Currency Order', link: '/purchase/currency/order'},
      {sublabel: 'Currency Invoice', link: '/purchase/currency/invoice'}
    ]
  },
  {
    label: 'Sales',
    icon: 'bx bx-purchase-tag  icon nav-icon',
    subItem: [
      {sublabel: 'Metal Order', link: '/sales/metal/order'},
      {sublabel: 'Metal Invoice', link: '/sales/metal/invoice'},
      {sublabel: 'Currency Order', link: '/sales/currency/order'},
      {sublabel: 'Currency Invoice', link: '/sales/currency/invoice'}
    ]
  },

  {
    label: 'Reports',
    icon: 'bx bxs-report icon nav-icon',
    subItem: [
      {sublabel: 'Ledger', link: '/report/ledger'},
      {sublabel: 'Reconciliation ', link: '/report/reconciliation'},
      {sublabel: 'Trial Balance', link: '/report/trial-balance'},
      {sublabel: 'Stock', link: '/report/Stock'},
      {sublabel: 'Party Stock', link: '/report/PartyStock'},
      {sublabel: 'Profit & Loss', link: '/master/profitloss'}
    ]
  },
  {
    label: 'Admin',
    icon: 'bx bx-user icon nav-icon',
    subItem: [
      {sublabel: 'Account Groups', link: '/master/accountGroup'},
      {sublabel: 'Accounts', link: '/master/acoounts'},
      {sublabel: 'Products', link: '/master/products'},
      {sublabel: 'Users', link: '/master/users'},
      {sublabel: 'Cities', link: '/master/city'},
      {sublabel: 'Configuration', link: '/master/configuration'}
    ]
  }

  // {
  //   label: "Applications",
  //   isMainMenu: true,
  // },
  // {
  //   label: "Calendar",
  //   icon: "bx bx-calendar icon nav-icon",
  //   url: "/apps-calendar",
  // },
  // {
  //   label: "Chat",
  //   icon: "bx bx-chat icon nav-icon",
  //   url: "/apps-chat",
  //   issubMenubadge: true,
  //   bgcolor: "bg-danger",
  //   badgeValue: "Hot",
  // },
  // {
  //   label: "Email",
  //   icon: "bx bx-envelope icon nav-icon",
  //   subItem: [
  //     { sublabel: "Inbox", link: "/email-inbox" },
  //     { sublabel: "Read Email", link: "/email-read" },
  //   ],
  // },

  // {
  //   label: "Applications",
  //   isMainMenu: true,
  // },
  // {
  //   label: "Calendar",
  //   icon: "bx bx-calendar icon nav-icon",
  //   url: "/apps-calendar",
  // },
  // {
  //   label: "Chat",
  //   icon: "bx bx-chat icon nav-icon",
  //   url: "/apps-chat",
  //   issubMenubadge: true,
  //   bgcolor: "bg-danger",
  //   badgeValue: "Hot",
  // },
  // {
  //   label: "Email",
  //   icon: "bx bx-envelope icon nav-icon",
  //   subItem: [
  //     { sublabel: "Inbox", link: "/email-inbox" },
  //     { sublabel: "Read Email", link: "/email-read" },
  //   ],
  // },

  // {
  //   label: "Invoices",
  //   icon: "bx bx-receipt icon nav-icon",

  //   subItem: [
  //     { sublabel: "Invoice List", link: "/invoices-list" },
  //     { sublabel: "Invoice Detail", link: "/invoices-detail" },
  //   ],
  // },
  // {
  //   label: "Contacts",
  //   icon: "bx bxs-user-detail icon nav-icon",

  //   subItem: [
  //     { sublabel: "User Grid", link: "/contacts-grid" },
  //     { sublabel: "User List", link: "/contacts-list" },
  //     { sublabel: "User Profile", link: "/contacts-profile" },
  //   ],
  // },

  // {
  //   label: "Pages",
  //   isMainMenu: true,
  // },

  // {
  //   label: "Authentication",
  //   icon: "bx bx-user-circle icon nav-icon",

  //   subItem: [
  //     { sublabel: "Login", link: "/auth-login" },
  //     { sublabel: "Register", link: "/auth-register" },
  //     { sublabel: "Recover Password", link: "/auth-recoverpw" },
  //     { sublabel: "Lock Screen", link: "/auth-lock-screen" },
  //     { sublabel: "Log Out", link: "/auth-logout" },
  //     { sublabel: "Confirm Mail", link: "/auth-confirm-mail" },
  //     { sublabel: "Email Verification", link: "/auth-email-verification" },
  //     {
  //       sublabel: "Two Step Verification",
  //       link: "/auth-two-step-verification",
  //     },
  //   ],
  // },
  // {
  //   label: "Utility",
  //   icon: "bx bx-file icon nav-icon",

  //   subItem: [
  //     { sublabel: "Starter Page", link: "/pages-starter" },
  //     { sublabel: "Maintenance", link: "/pages-maintenance" },
  //     { sublabel: "Coming Soon", link: "/pages-comingsoon" },
  //     { sublabel: "Timeline", link: "/pages-timeline" },
  //     { sublabel: "FAQs", link: "/pages-faqs" },
  //     { sublabel: "Pricing", link: "/pages-pricing" },
  //     { sublabel: "Error 404", link: "/pages-404" },
  //     { sublabel: "Error 500", link: "/pages-500" },
  //   ],
  // },
  // {
  //   label: "Components",
  //   isMainMenu: true,
  // },
  // {
  //   label: "Bootstrap",
  //   icon: "bx bxl-bootstrap icon nav-icon",

  //   subItem: [
  //     { sublabel: "Alerts", link: "/ui-alerts" },
  //     { sublabel: "Buttons", link: "/ui-buttons" },
  //     { sublabel: "Cards", link: "/ui-cards" },
  //     { sublabel: "Carousel", link: "/ui-carousel" },
  //     { sublabel: "Dropdowns", link: "/ui-dropdowns" },
  //     { sublabel: "Grid", link: "/ui-grid" },
  //     { sublabel: "Images", link: "/ui-images" },
  //     { sublabel: "Modals", link: "/ui-modals" },
  //     { sublabel: "Offcanvas", link: "/ui-offcanvas" },
  //     { sublabel: "Placeholders", link: "/ui-placeholders" },
  //     { sublabel: "Progress Bars", link: "/ui-progressbars" },
  //     { sublabel: "Tabs & Accordions", link: "/ui-tabs-accordions" },
  //     { sublabel: "Typography", link: "/ui-typography" },
  //     { sublabel: "Video", link: "/ui-video" },
  //     { sublabel: "General", link: "/ui-general" },
  //     { sublabel: "Colors", link: "/ui-colors" },
  //   ],
  // },

  // {
  //   label: "Extended",
  //   icon: "bx bx-disc icon nav-icon",

  //   subItem: [
  //     { sublabel: "Lightbox", link: "/extended-lightbox" },
  //     { sublabel: "Range Slider", link: "/extended-rangeslider" },
  //     { sublabel: "Rating", link: "/extended-rating" },
  //     { sublabel: "Notifications", link: "/extended-notifications" },
  //   ],
  // },

  // {
  //   label: "Forms",
  //   icon: "bx bxs-eraser icon nav-icon",
  //   subItem: [
  //     { sublabel: "Basic Elements", link: "/form-elements" },
  //     { sublabel: "Validation", link: "/form-validation" },
  //     { sublabel: "Advanced Plugins", link: "/form-advanced" },
  //     { sublabel: "Editors", link: "/form-editor" },
  //     { sublabel: "File Upload", link: "/form-uploads" },
  //     { sublabel: "Wizard", link: "/form-wizard" },
  //     { sublabel: "Mask", link: "/form-mask" },
  //   ],
  // },
  // {
  //   label: "Tables",
  //   icon: "bx bx-list-ul icon nav-icon",
  //   subItem: [
  //     { sublabel: "Bootstrap Basic", link: "/tables-basic" },
  //     { sublabel: "Advance Tables", link: "/table-advanced" },
  //   ],
  // },
  // {
  //   label: "Charts",
  //   icon: "bx bxs-bar-chart-alt-2 icon nav-icon",
  //   subItem: [
  //     { sublabel: "Apex Charts", link: "/charts-apex" },
  //     { sublabel: "Chartjs", link: "/charts-chartjs" },
  //   ],
  // },
  // {
  //   label: "Icons",
  //   icon: "bx bx-aperture icon nav-icon",
  //   subItem: [
  //     { sublabel: "Feather", link: "/icons-feather" },
  //     { sublabel: "Boxicons", link: "/icons-boxicons" },
  //     { sublabel: "Material Design", link: "/icons-materialdesign" },
  //     { sublabel: "Dripicons", link: "/icons-dripicons" },
  //     { sublabel: "Font Awesome 5", link: "/icons-fontawesome" },
  //   ],
  // },
  // {
  //   label: "Maps",
  //   icon: "bx bx-map icon nav-icon",
  //   subItem: [
  //     { sublabel: "Google", link: "/maps-google" },
  //     { sublabel: "Vector", link: "/maps-vector" },
  //     { sublabel: "Leaflet", link: "/maps-leaflet" },
  //   ],
  // },
  // {
  //   label: "Multi Level",
  //   icon: "bx bx-share-alt icon nav-icon",
  //   subItem: [
  //     { sublabel: "Level 1.1", link: "/#" },
  //     {
  //       sublabel: "Level 1.2",
  //       link: "/#",
  //       subMenu: [{ title: "Level 2.1" }, { title: "Level 2.2" }],
  //     },
  //   ],
  // },
];

export default SidebarData;
