

export const ACCOUNT_FETCH_START = 'ACCOUNT_FETCH_START';
export const ACCOUNT_FETCH_SUCCESS = 'ACCOUNT_FETCH_SUCCESS';
export const ACCOUNT_FETCH_ERROR = 'ACCOUNT_FETCH_ERROR';

export const ACCOUNT_GROUP_FETCH_SUCCESS = 'ACCOUNT_GROUP_FETCH_SUCCESS';
export const ADD_ACCOUNT_DATA = 'ADD_ACCOUNT_DATA';
export const REMOVE_ACCOUNT_DATA = 'REMOVE_ACCOUNT_DATA';
export const ACCOUNT_GROUP_FETCH_COUNT_SUCCESS = 'ACCOUNT_GROUP_FETCH_COUNT_SUCCESS';

export const GET_ACCOUNT_BY_BANK = 'GET_ACCOUNT_BY_BANK';
export const FETCH_ACCOUNT_TRANSACTION_EXISTENCE = 'FETCH_ACCOUNT_TRANSACTION_EXISTENCE';
