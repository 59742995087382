import {Field, FieldArray, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, FormFeedback, Input, Label, Modal, Row, Table} from 'reactstrap';
import * as Yup from 'yup';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import {addJournals} from '../../store/journals/actions';
import {REMOVE_ACCOUNT_DATA} from '../../store/Accounts/actionTypes';

function OperationsModal(props) {
  const {currentUser, jvNumber, accId, accountsList, date} = props;
  const [crAmount, setCrAmont] = useState('');
  const [drAmount, setDrAmount] = useState();
  const [formDate, setFormDate] = useState([new Date()]);
  const inputRefs = useRef([]);
  const selectRefs = useRef([]);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({
        type: REMOVE_ACCOUNT_DATA
      });
    };
  }, []);

  useEffect(() => {
    setFormDate(moment(currentUser?.jvDate).format('DD/MM/YYYY'));
  }, [currentUser]);

  const calculateTotal = (values) => {
    const result = values?.journalDetailsList.reduce(
      (count, currentValue) => (currentValue.cdMode === 'C' ? count + Number(currentValue.amount) : count),
      0
    );
    setCrAmont(result.toFixed(3));
    return result.toFixed(3); // Mult// Multiply by a factor of 10, for example
  };
  const calculateTotalDebit = (values) => {
    const result = values?.journalDetailsList.reduce(
      (count, currentValue) => (currentValue.cdMode === 'D' ? count + Number(currentValue.amount) : count),
      0
    );
    setDrAmount(result.toFixed(3));
    return result.toFixed(3); // Multiply by a factor of 10, for example
  };

  const validationSchema = Yup.object().shape({
    narration: Yup.string().trim('Please add narration').strict(false).required('Please add narration'),
    journalDetailsList: Yup.array().of(
      Yup.object().shape({
        accountID: Yup.string().required('Please select account'),
        cdMode: Yup.string().required('Please select type'),
        amount: Yup.number().min(0, 'Enter valid amount').required('Please enter amount').moreThan(0, 'amount must be greater than zero')
      })
    )
  });

  const SelectField1: React.SFC<ReactSelectProps & FieldProps> = ({options, index, field, form}) => (
    <Select
      ref={(el) => (selectRefs.current[index] = el)}
      className='w250'
      options={options}
      name={field.name}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      openMenuOnFocus={true}
      value={options ? options.find((option) => option.value === field.value) : ''}
      onChange={(option) => {
        form.setFieldValue(field.name, option.value);
        form.setFieldValue(`journalDetailsList[${index}].accountName`, option.label);
        form.setFieldValue(`journalDetailsList[${index}].operationToPerform`, 1);
        form.setFieldValue(field.name, option.value);
      }}
      onBlur={field.onBlur}
      noOptionsMessage={() => {
        return (
          <button className='btn btn-primary btn-block' onClick={() => props?.tog_invoice()}>
            Add new account
          </button>
        );
      }}
    />
  );

  const SelectField2: React.SFC<ReactSelectProps & FieldProps> = ({options, index, field, form}) => (
    <Select
      className='w90'
      options={options}
      name={field.name}
      openMenuOnFocus={true}
      value={options ? options.find((option) => option.value === field.value) : ''}
      onChange={(option) => {
        form.setFieldValue(`journalDetailsList[${index}].cdName`, option.label);
        form.setFieldValue(`journalDetailsList[${index}].operationToPerform`, 1);
        form.setFieldValue(field.name, option.value);
      }}
      onBlur={field.onBlur}
    />
  );

  const cdModeDAta = [
    {
      name: 'Cr',
      value: 'C'
    },
    {
      name: 'Dr',
      value: 'D'
    }
  ];

  return (
    <>
      <Modal size='lg' isOpen={props?.modal_journals} onClosed={() => props?.handleModalClose()} className='addInvoiceModal' centered>
        <div className='modal-header'>
          <h5 className='modal-title' id='addInvoiceModalLabel'>
            {currentUser ? 'Edit' : 'New'} Journal
          </h5>
          <button
            onClick={() => {
              props?.handleModalClose();
            }}
            type='button'
            className='btn-close'
            aria-label='Close'
          ></button>
        </div>

        <div className='modal-body p-4'>
          <Formik
            initialValues={{
              jvNo: currentUser ? currentUser?.jvNo : jvNumber,
              // jvDate: "",
              // tcAmount: setCurrentUser ? currentUser.tcAmount : "",
              narration: currentUser ? currentUser.narration : '',
              journalDetailsList: currentUser.journalDetailsList || [
                {
                  accountID: '',
                  accountName: '',
                  cdMode: 'C',
                  cdName: 'Cr',
                  amount: '',
                  operationToPerform: 1,
                  id: 0,
                  jvid: 0
                }
              ]
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              values.tcAmount = crAmount;
              values.tdAmount = drAmount;
              values.jvDate = moment(formDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

              // values.jvDate = moment(formDate[0]).format('YYYY-MM-DD');

              if (crAmount !== drAmount) {
                toast.info('Debit amount and credit amount should be same');
                return;
              }

              values.id = accId || 0;
              if (currentUser?.id) {
                values.id = currentUser.id;
                values.operationToPerform = 2;

                const commonIds = values.journalDetailsList.map((obj) => obj.id);
                const modifiedArray = [
                  ...values.journalDetailsList.map((obj) => ({
                    ...obj,
                    operationToPerform: commonIds.includes(obj.id) ? 2 : 3
                  })),
                  ...currentUser.journalDetailsList
                    .filter((obj) => !commonIds.includes(obj.id))
                    .map((obj) => ({
                      ...obj,
                      operationToPerform: 3
                    }))
                ];

                values.journalDetailsList = modifiedArray;
              } else {
                values.operationToPerform = 1;
              }
              dispatch(addJournals(values, date));
              props?.handleModalClose();
            }}
          >
            {({values, handleSubmit, handleBlur, handleChange, errors, getFieldProps, setFieldValue, touched}) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md='6'>
                      <Label>Voucher Number</Label>
                      <Input
                        name='jvNo'
                        className='form-control'
                        placeholder='Enter Account Code'
                        type='text'
                        disabled
                        value={values.jvNo}
                      />
                    </Col>

                    <Col md='6'>
                      <Label>Voucher Date</Label>

                      <Flatpickr
                        name='jvDate'
                        className='form-control d-block'
                        placeholder='Please select date'
                        value={formDate}
                        options={{
                          mode: 'single',
                          disableMobile: true,
                          dateFormat: 'd/m/Y'
                        }}
                        onChange={(e) => {
                          if (e.length) {
                            setFormDate(e[0]);
                            handleChange('jvDate');
                          } else {
                            setFormDate([new Date()]);
                          }
                        }}
                      />
                      {!formDate ? <span className='text-danger font-size-12'>Please select date</span> : null}

                      {errors.jvDate ? <span className='text-danger font-size-12'>{errors.jvDate}</span> : null}
                    </Col>
                  </Row>

                  <Row className='mt-4'>
                    <Col>
                      <div className='table-responsive react-table '>
                        <Table bordered size='sm'>
                          <thead>
                            <tr style={{background: '#f5f6f8'}}>
                              <th>Dr/Cr</th>
                              <th>Ledger Account</th>
                              <th>Credit Amount</th>
                              <th>Debit Amount </th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            <FieldArray name='journalDetailsList'>
                              {({remove, push}) => (
                                <>
                                  {values.journalDetailsList.map((_, index) => (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          <Field
                                            name={`journalDetailsList[${index}].cdMode`}
                                            component={SelectField2}
                                            index={index}
                                            options={cdModeDAta?.map((value) => ({
                                              value: value.value,
                                              label: value.name
                                            }))}
                                          />

                                          {errors?.journalDetailsList?.length && errors?.journalDetailsList[index]?.cdMode ? (
                                            <div>{errors?.journalDetailsList[index]?.cdMode}</div>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Field
                                            name={`journalDetailsList[${index}].accountID`}
                                            component={SelectField1}
                                            index={index}
                                            options={accountsList}
                                            ref={(el) => (selectRefs.current[index] = el)}
                                          />
                                          {errors?.journalDetailsList?.length && errors?.journalDetailsList[index]?.accountID ? (
                                            <div className='text-danger font-size-12 mt-1'>
                                              {errors?.journalDetailsList[index]?.accountID}
                                            </div>
                                          ) : null}
                                        </td>
                                        <td>
                                          {values?.journalDetailsList?.length && values?.journalDetailsList[index]?.cdMode === 'C' && (
                                            <Input
                                              name={`journalDetailsList[${index}].amount`}
                                              className='w120'
                                              placeholder='Enter Amount'
                                              type='number'
                                              value={values?.journalDetailsList[index].amount}
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                const val = ('' + value).replace('.', '');
                                                if (val?.length > 3) {
                                                  const suppressedValue = Number(+val / 1000).toFixed(3);
                                                  setFieldValue(`journalDetailsList[${index}].amount`, suppressedValue);
                                                } else {
                                                  setFieldValue(`journalDetailsList[${index}].amount`, val);
                                                }
                                              }}
                                              ref={(el) => (inputRefs.current[index] = el)}
                                              onBlur={async (e) => {
                                                if (e?.target.value?.length > 3) {
                                                  const formatted = Number(e?.target.value / 1000).toFixed(3);

                                                  setFieldValue(`journalDetailsList[${index}].amount`, e.target.value);
                                                } else {
                                                  const formatted = Number(e?.target.value / 1000).toFixed(3);

                                                  setFieldValue(`journalDetailsList[${index}].amount`, formatted);
                                                }

                                                // tab functionality
                                                var val;
                                                const lastElm = values.journalDetailsList.filter((data) => data.cdMode === 'C');
                                                if (lastElm[lastElm.length - 1].amount.length > 3) {
                                                  val = lastElm[lastElm.length - 1].amount;
                                                } else {
                                                  val = (lastElm[lastElm.length - 1].amount / 1000).toFixed(3);
                                                }
                                                const allElm = lastElm
                                                  ?.slice(0, -1)
                                                  .reduce((total, obj) => total + parseFloat(obj.amount), 0);

                                                const dbt1 = Number(calculateTotalDebit(values));
                                                const cbt1 = Number(allElm) + Number(val);

                                                const diff = Math.abs(cbt1 - dbt1);

                                                if (cbt1.toFixed(3) === dbt1.toFixed(3)) {
                                                  return;
                                                }

                                                if (cbt1 > dbt1) {
                                                  await push({
                                                    accountID: '',
                                                    accountName: '',
                                                    cdMode: 'D',
                                                    operationToPerform: 1,
                                                    cdName: 'Dr',
                                                    amount: diff.toFixed(3)
                                                  });
                                                }
                                                if (dbt1 > cbt1) {
                                                  await push({
                                                    accountID: '',
                                                    accountName: '',
                                                    cdMode: 'C',
                                                    operationToPerform: 1,
                                                    cdName: 'Cr',
                                                    amount: diff.toFixed(3)
                                                  });
                                                }
                                                selectRefs.current[selectRefs.current.length - 1]?.focus();
                                              }}
                                              invalid={
                                                errors?.journalDetailsList?.length && errors?.journalDetailsList[index]?.amount
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                          {errors?.journalDetailsList?.length &&
                                          values?.journalDetailsList?.length &&
                                          values?.journalDetailsList[index]?.cdMode === 'C' &&
                                          errors?.journalDetailsList[index]?.amount ? (
                                            <div className='text-danger font-size-12 mt-1'>{errors?.journalDetailsList[index]?.amount}</div>
                                          ) : null}
                                        </td>
                                        <td>
                                          {values?.journalDetailsList?.length && values?.journalDetailsList[index]?.cdMode === 'D' && (
                                            <Input
                                              name={`journalDetailsList[${index}].amount`}
                                              className='w120'
                                              placeholder='Enter Amount'
                                              type='number'
                                              value={values?.journalDetailsList[index].amount}
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                const val = ('' + value).replace('.', '');
                                                if (val?.length > 3) {
                                                  const suppressedValue = Number(+val / 1000).toFixed(3);
                                                  setFieldValue(`journalDetailsList[${index}].amount`, suppressedValue);
                                                } else {
                                                  setFieldValue(`journalDetailsList[${index}].amount`, val);
                                                }
                                              }}
                                              ref={(el) => (inputRefs.current[index] = el)}
                                              onBlur={async (e) => {
                                                if (e?.target.value?.length > 3) {
                                                  setFieldValue(`journalDetailsList[${index}].amount`, e.target.value);
                                                  setDrAmount(drAmount + e.target.value);
                                                } else {
                                                  const formatted = Number(e?.target.value / 1000).toFixed(3);

                                                  setFieldValue(`journalDetailsList[${index}].amount`, formatted);
                                                  setDrAmount(drAmount + formatted);
                                                }

                                                // tab functionality
                                                var val;
                                                const lastElm = values.journalDetailsList.filter((data) => data.cdMode === 'D');
                                                if (lastElm[lastElm.length - 1].amount.length > 3) {
                                                  val = lastElm[lastElm.length - 1].amount;
                                                } else {
                                                  val = (lastElm[lastElm.length - 1].amount / 1000).toFixed(3);
                                                }
                                                const allElm = lastElm
                                                  ?.slice(0, -1)
                                                  .reduce((total, obj) => total + parseFloat(obj.amount), 0);

                                                const cbt = Number(calculateTotal(values));
                                                const dbt = Number(allElm) + Number(val);

                                                const diff = Math.abs(dbt - cbt);

                                                if (dbt.toFixed(3) === cbt.toFixed(3)) {
                                                  return;
                                                }

                                                if (dbt > cbt) {
                                                  await push({
                                                    accountID: '',
                                                    accountName: '',
                                                    cdMode: 'C',
                                                    operationToPerform: 1,
                                                    cdName: 'Cr',
                                                    amount: diff.toFixed(3)
                                                  });
                                                }
                                                if (cbt > dbt) {
                                                  await push({
                                                    accountID: '',
                                                    accountName: '',
                                                    cdMode: 'D',
                                                    operationToPerform: 1,
                                                    cdName: 'Dr',
                                                    amount: diff.toFixed(3)
                                                  });
                                                }
                                                selectRefs.current[selectRefs.current.length - 1]?.focus();
                                              }}
                                              invalid={
                                                errors?.journalDetailsList?.length && errors?.journalDetailsList[index]?.amount
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                          {errors?.journalDetailsList?.length &&
                                          values?.journalDetailsList?.length &&
                                          values?.journalDetailsList[index]?.cdMode == 'D' &&
                                          errors?.journalDetailsList[index]?.amount ? (
                                            <div className='text-danger font-size-12'>{errors?.journalDetailsList[index]?.amount}</div>
                                          ) : null}
                                        </td>

                                        <td className='d-flex justify-content-center mt-2 border-0'>
                                          {index === 0 ? null : (
                                            <span className='cursor text-danger px-0' onClick={() => remove(index)}>
                                              {' '}
                                              <i className='bx bx-trash-alt text-danger font-size-18'></i>
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  ))}

                                  <span
                                    className='cursor text-danger'
                                    onClick={() => {
                                      var val1;
                                      const lastElm1 = values.journalDetailsList.filter((data) => data.cdMode === 'D');
                                      if (lastElm1[lastElm1.length - 1]?.amount.length > 3) {
                                        val1 = lastElm1[lastElm1.length - 1]?.amount;
                                      } else {
                                        val1 = (lastElm1[lastElm1.length - 1]?.amount / 1000).toFixed(3);
                                      }
                                      const allElm1 = lastElm1?.slice(0, -1).reduce((total, obj) => total + parseFloat(obj.amount), 0);

                                      var val;
                                      const lastElm = values.journalDetailsList.filter((data) => data.cdMode === 'C');
                                      if (lastElm[lastElm.length - 1]?.amount.length > 3) {
                                        val = lastElm[lastElm.length - 1]?.amount;
                                      } else {
                                        val = (lastElm[lastElm.length - 1]?.amount / 1000).toFixed(3);
                                      }
                                      const allElm = lastElm?.slice(0, -1).reduce((total, obj) => total + parseFloat(obj.amount), 0);

                                      const cbt1 = Number(allElm) + Number(val) || 0;

                                      const dbt1 = Number(allElm1) + Number(val1) || 0;

                                      const diff = Math.abs(cbt1 - dbt1);
                                      if (Number(cbt1) === Number(dbt1)) {
                                        push({
                                          accountID: '',
                                          accountName: '',
                                          cdMode: 'C',
                                          operationToPerform: 1,
                                          cdName: 'Cr',
                                          amount: ''
                                        });
                                      }

                                      if (cbt1 > dbt1) {
                                        push({
                                          accountID: '',
                                          accountName: '',
                                          cdMode: 'D',
                                          operationToPerform: 1,
                                          cdName: 'Dr',
                                          amount: diff.toFixed(3)
                                        });
                                      }
                                      if (dbt1 > cbt1) {
                                        push({
                                          accountID: '',
                                          accountName: '',
                                          cdMode: 'C',
                                          operationToPerform: 1,
                                          cdName: 'Cr',
                                          amount: diff.toFixed(3)
                                        });
                                      }
                                    }}
                                  >
                                    {' '}
                                    <i
                                      className='bx
																	bx-plus text-dark bg-light font-size-26 text=bold rounded-circle m-2'
                                    ></i>
                                  </span>
                                </>
                              )}
                            </FieldArray>
                          </tbody>
                          <thead>
                            <tr style={{background: '#f5f6f8'}}>
                              <th></th>
                              <th>Total:</th>
                              <th>{calculateTotal(values)}</th>
                              <th>{calculateTotalDebit(values)}</th>
                              <th></th>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <Label>Narration</Label>
                      <Input
                        name='narration'
                        className='form-control'
                        placeholder='Enter Narration'
                        type='textarea'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.narration || ''}
                        invalid={touched.narration && errors.narration ? true : false}
                      />
                      {touched.narration && errors.narration ? (
                        <FormFeedback type='invalid'>
                          <div>{errors.narration}</div>
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <div className='mt-3 pt-0 text-end'>
                    <Button className='btn  btn-block' color='primary' type='submit'>
                      {currentUser ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

export default OperationsModal;
