/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
import axios from 'axios';
import {
  DOWNLOAD_LEDGER_REPORT,
  DOWNLOAD_LEDGER_REPORT_EXCEL,
  LEADER_TRANSACTION_LIST,
  LEADER_LIST_JOURNAL,
  LEDGER_REPORT,
  LEDGER_LIST_BANKBOOK,
  LEDGER_LIST_CASHBOOK,
  LEDGER_LIST_PURCHASE,
  LEDGER_LIST_SALES,
  LEDGER_RECONCILIATION,
  LEDGER_UPDATE_RECONCILIATION,
  DOWNLOAD_TRAIL_REPORT_PDF,
  DOWNLOAD_RECONCILIATION_REPORT_EXCEL,
  DOWNLOAD_TRAIL_RECONCILIATION_PDF
} from '../apiRoutes';
import {LEADER_TRANSACTION_DATA, LEDGER_FETCH_SUCCESS, RECONCILIATION_DATES, RECONCILIATION_FETCH_SUCCESS} from './actionTypes';

import {errorHandlers} from '../../helpers/errorHandlers';
import {loaderStart, loaderStop} from '../actions';
import moment from 'moment';

export const getLedgerList = (date, accId) => async (dispatch) => {
  dispatch(loaderStart());
  const date1 = moment(date[0]).format('MM/DD/YYYY');
  const date2 = moment(date[1]).format('MM/DD/YYYY');
  axios
    .get(LEDGER_REPORT + '?fromDate=' + date1 + '&toDate=' + date2 + '&id=' + accId)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: LEDGER_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const downloadLedgerList = (date, accId, narration, name, type, aacName) => async (dispatch) => {
  dispatch(loaderStart());
  const date1 = moment(date[0]).format('MM/DD/YYYY');
  const date2 = moment(date[1]).format('MM/DD/YYYY');
  let name1 = name?.trim();
  if (!name1) {
    name1 = 'Ledger Report';
  }

  let tag;
  if (type == 'pdf') {
    tag = '.pdf';
  } else {
    tag = '.csv';
  }
  const url = type == 'csv' ? DOWNLOAD_LEDGER_REPORT_EXCEL : DOWNLOAD_LEDGER_REPORT;
  axios
    .get(url + '?fromDate=' + date1 + '&toDate=' + date2 + '&id=' + accId + '&IsNarration=' + narration + '&AccountName=' + aacName, {
      responseType: 'blob'
    })
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.download = name1 + tag;
      link.click();
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const downloadReconciliationList = (date, accId, narration, name, type, aacName, recon) => async (dispatch) => {
  dispatch(loaderStart());
  const date1 = moment(date[0]).format('MM/DD/YYYY');
  const date2 = moment(date[1]).format('MM/DD/YYYY');
  let name1 = name?.trim();
  if (!name1) {
    name1 = 'Reconciliation Report';
  }

  let tag;
  if (type == 'pdf') {
    tag = '.pdf';
  } else {
    tag = '.csv';
  }
  const url = type == 'csv' ? DOWNLOAD_RECONCILIATION_REPORT_EXCEL : DOWNLOAD_TRAIL_RECONCILIATION_PDF;
  axios
    .get(
      url +
        '?fromDate=' +
        date1 +
        '&toDate=' +
        date2 +
        '&id=' +
        accId +
        '&IsNarration=' +
        narration +
        '&AccountName=' +
        aacName +
        '&reconciliation=' +
        recon,
      {
        responseType: 'blob'
      }
    )
    .then((response) => {
      dispatch(loaderStop());
      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.download = name1 + tag;
      link.click();
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const getLeaderTransaction = (id, type) => async (dispatch) => {
  dispatch(loaderStart());

  let url;
  switch (type) {
    case 'Transaction':
      url = LEADER_TRANSACTION_LIST;
      break;
    case 'Journal':
      url = LEADER_LIST_JOURNAL;
      break;
    case 'Sales - Metal':
      url = LEDGER_LIST_SALES;
      break;
    case 'Sales - Currency':
      url = LEDGER_LIST_SALES;
      break;
    case 'Purchase - Currency':
      url = LEDGER_LIST_PURCHASE;
      break;
    case 'Purchase - Metal':
      url = LEDGER_LIST_PURCHASE;
      break;
    case 'Bank Tran Journal':
      url = LEDGER_LIST_BANKBOOK;
      break;
    case 'Cash Book':
      url = LEDGER_LIST_CASHBOOK;
      break;
    default:
      url = LEADER_TRANSACTION_LIST;
      break;
  }
  axios
    .get(url + '?id=' + id)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: LEADER_TRANSACTION_DATA,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const getReconciliationList = (date, accId, reconciliation) => async (dispatch) => {
  dispatch(loaderStart());
  const date1 = moment(date[0]).format('MM/DD/YYYY');
  const date2 = moment(date[1]).format('MM/DD/YYYY');
  axios
    .get(LEDGER_RECONCILIATION + '?fromDate=' + date1 + '&toDate=' + date2 + '&id=' + accId + '&reconciliation=' + reconciliation)
    .then((response) => {
      dispatch(loaderStop());
      dispatch({
        type: LEDGER_FETCH_SUCCESS,
        payload: response
      });
    })
    .catch((error) => {
      errorHandlers(error);
      dispatch(loaderStop());
    });
};

export const updateReconciliation = (tranId, reconciliation, data) => async (dispatch) => {
  dispatch(loaderStart());
  axios
    .post(`${LEDGER_UPDATE_RECONCILIATION}?tranID=${tranId}&reconciliation=${reconciliation}`)
    .then((response) => {
      dispatch(getReconciliationList(data?.date, data?.accId, data?.recon || 0));
    })
    .catch((error) => {
      dispatch(loaderStop());
      errorHandlers(error);
    });
};
